@import 'styles/variables';

.br-setup-guide__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: @shadow-xl;
  border-radius: 8px;
  position: absolute;
  right: 36px;
  bottom: 36px;
  padding: 8px;
  z-index: 999;
  cursor: pointer;
  background-color: @white;

  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path,
  .ant-progress-status-success
    .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: @deep-teal;
  }
}

.br-setup-guide__hidden {
  background-color: @bg-selected;
  border: 2px solid @teal-300;
}

.br-setup-guide__dropdown-overlay {
  background-color: @white;
  max-width: 392px;
  box-shadow: @shadow-xl;
  padding: 24px;
  border-radius: 8px;
}
