@import './fonts-classes.less';

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

.font(@rules) {
  @rules();
}

:root[dir='ltr'] {
  --font-700: 'display-sm/bold';
  --font-600: 'display-xs/Semibold';
  --font-500: 'text/Medium';
  --font-400: 'text/regular';
}

:root[dir='rtl'] {
  --font-700: 'Cairo, Black';
  --font-600: 'Cairo, Bold';
  --font-500: 'Cairo, SemiBold';
  --font-400: 'Cairo, Regular';
}

@English-400: 'text/regular';
@Arabic-700: 'Cairo, Bold';
@Arabic-600: 'Cairo, SemiBold';
@Arabic-400: 'Cairo, Regular';

@font-700: var(--font-700);
@font-600: var(--font-600);
@font-500: var(--font-500);
@font-400: var(--font-400);

@header-height: 72px;
@content-width: 250px;

@text-color: @text-gray-dark;
@text-color-1: #1f2937;
@text-color-2: #8f9bb3;
@text-color-3: #b0b8cb;
@text-color-4: #718096;
@text-color-5: #222b45;
@text-color-6: #6d7278;
@text-color-7: #7171a6;
@text-color-8: #ced0da;
@text-color-9: #8888b8;
@text-color-10: #000000;
@text-color-11: #131415;
@text-color-12: #a0aec0;
@text-color-13: #616e7e;
@text-color-14: #030303;
@text-color-15: #ffffff;
@text-color-16: #cacaca;
@text-color-17: #9daec2;
@text-color-18: #909cad;
@text-color-19: #0f0e0ed9;
@text-color-20: #000000a6;
@text-color-21: #0000ff;
@text-color-22: #0091ff;
@text-color-23: #6236ff;
@text-color-24: #80879b;
@text-color-25: #3da20b;
@text-color-26: #ffbd00;
@text-color-27: #e30613;
@text-color-28: #ff4d4f;
@text-color-29: #0098a5;
@text-color-30: #cbd5e0;
@text-color-31: #6c738a;
@text-color-32: #abafbd;
@text-color-33: #4a5568;
@text-color-34: #d5d7de;
@text-color-35: #0080ff;
@text-color-36: #47c1bf;
@text-color-37: #ff4c4c;
@text-color-38: #344064;
@text-color-39: #00c092;
@text-color-40: #21a9b6;
@text-color-41: #3b82f6;
@text-color-42: #8e8103;
@text-color-43: #d69e2e;
@text-color-44: #7b61ff;
@text-color-45: #4e80ef;
@text-color-46: #52a6b4;
@text-color-47: #ff0100;
@text-color-48: #027a48;
@text-color-49: #ff8080;
@text-color-50: #dc6803;
@text-color-51: #9ca3af;
@border-color: #9daec2;
@border-color-2: #eff1f3;
@border-color-3: #d9d9d9;
@border-color-5: #c5cee0;
@border-color-6: #d8dce5;
@border-color-7: #e4e7ee;
@border-color-8: #ebedf0;
@border-color-9: #a0aec0;
@border-color-10: #ffffff;
@border-color-11: #0000001f;
@border-color-12: #dee4eb;
@border-color-13: #d6dee7;
@border-color-14: #e8e8e8;
@border-color-15: #e30613;
@border-color-16: #0098a5;
@border-color-17: #cbd5e0;
@border-color-18: #e2e8f0;
@border-color-19: #cbd5e0;
@border-color-20: #e2e8f0;
@border-color-21: #abafbd6e;
@border-color-22: #eec201;
@border-color-23: #3b82f6;
@border-color-24: #f6e05e;
@border-color-25: #00b27c;
@border-color-26: #e5e7eb;
@border-color-27: #52a6b480;
@border-color-28: #0080ff80;
@border-color-29: #7b61ff80;
@border-color-30: #d69e2e80;
@border-color-31: #cbd5e033;
@border-color-32: #027a48;
@border-color-33: #2d3859;
@background-color: #ffffff;
@background-color-2: #f8f8f9;
@background-color-3: #f9f9f9;
@background-color-4: #e5e9ee;
@background-color-5: #f7fafc;
@background-color-6: #f6f7f8;
@background-color-7: #f3f7fc;
@background-color-8: #d7dde4;
@background-color-9: #6236ff1a;
@background-color-10: #ffbd001a;
@background-color-11: #00b8870d;
@background-color-12: #afafaf;
@background-color-13: #e1e6ec;
@background-color-14: #0091ff;
@background-color-15: #e30613;
@background-color-16: #0098a5;
@background-color-17: #f7fafc;
@background-color-18: #edf2f7;
@background-color-19: #fff5f5;
@background-color-20: #fefddf;
@background-color-21: #e2faea;
@background-color-22: #f0fff4;
@background-color-23: #fffff0;
@background-color-24: #ecf8f8;
@background-color-25: #d5d7de;
@background-color-26: #faf089;
@background-color-27: #eff6ff;
@background-color-28: #0080ff;
@background-color-29: #3b82f6;
@background-color-30: #eec201;
@background-color-31: #0098a5;
@background-color-32: #6c738a;
@background-color-33: #3b82f61a;
@background-color-34: #00b8871a;
@background-color-35: #6c738a1a;
@background-color-36: #ff00001a;
@background-color-37: #eec2011a;
@background-color-38: #e2e8f0;
@background-color-39: #1314154d;
@background-color-40: #eff3fa;
@background-color-41: #e9f6f8;
@background-color-42: #ebf2fe;
@background-color-43: #e5f8f3;
@background-color-44: #e9e9e9;
@background-color-45: #21a9b6;
@background-color-46: #faf7e0;
@background-color-47: #21a9b61a;
@background-color-48: #f2fbf9;
@background-color-49: #e6e6e6;
@background-color-50: #efebd3;
@background-color-54: #f3f5f7;
@background-color-51: #343e5e;
@background-color-52: #1314154d;
@background-color-53: #fbf5ea;
@background-color-54: #f3f5f7;
@background-color-55: #ffc700;
@background-color-56: #ff8a00;
@background-color-57: #0080ff26;
@background-color-58: #7b61ff26;
@background-color-59: #d69e2e40;
@background-color-60: #52a6b426;
@background-color-61: #ffffff33;
@background-color-62: #f7fafc1a;
@background-color-63: #f7fafc33;
@background-color-64: #e0f4f5;
@background-color-65: #2d3859;
@background-color-66: #cbd5e0;
@background-color-67: #4b84ee1a;
@background-color-68: #00b88733;
@background-color-69: #fdb02233;
@background-color-70: #ffecec;
@background-color-71: #fafafa;
@background-color-72: #8acad0;
@background-color-73: #ff8080;
@background-color-74: #6c738a4d;
@background-color-75: #a0aec0;
@background-color-76: #d9f4f8;
@background-color-77: #f2fafb;
@background-color-78: #f2f4f7;
@box-shadow: #0000001a;
@box-shadow-2: #00000033;
@box-shadow-3: #0000000d;
@box-shadow-4: #a0aec04d;
@box-shadow-5: #cbd5e040;
@box-shadow-6: rgba(187, 60, 60, 0.051);
@box-shadow-7: #c0c7cd;
@tag-red-text-color: #e30613;
@tag-red-background-color: #ffe5e5;
@tag-orange-text-color: #ff5900;
@tag-orange-background-color: #ffeee5;
@tag-yellow-text-color: #ffdf00;
@tag-light-yellow-text-color: #ecc94b;
@tag-light-yellow-background-color: #fffff0;
@tag-yellow-background-color: #fffbe5;
@tag-green-text-color: #15b600;
@tag-green-background-color: #e7f7e5;
@tag-light-gray-text-color: #a0aec0;
@tag-light-gray-background-color: #f7fafc;
@tag-dark-gray-text-color: #6c738a;
@tag-dark-gray-background-color: #e2e8f0;
@tag-dark-gray-background-color: #e2e8f0;
@tag-light-brown-background-color: #fef3c7;
@terms-lite-color: #8a6b27;
@terms-pro-color: #ecbd00;
@primary-green-hover: #137a84;
@back-button-color: #9ba5bc;
@light-gray-icon-color: #a1aec0;
@dark-gray-icon-color: #4a5568;
@red-icon-color: #e30613;
@green-icon-color: #0098a5;
@timeline-step-icon-color: #eeeeee;
@timeline-step-icon-success-color: #3da20b;
@timeline-step-icon-error-color: #e30613;
@timeline-step-icon-warning-color: #ffbd00;
@tracking-icon-color: #e1e6ec;
@ant-icon-down-color: #00000040;
@side-bar-icon-color: #abafbd;
@read-notification-icon-color: #a0aec0;
@read-notification-icon-background-color: #f7fafc;
@read-notification-icon-hover-background-color: #edf2f7;
@uread-notification-icon-color: #ffffff;
@uread-notification-icon-background-color: #e30613;
@uread-notification-icon-hover-background-color: #d92d20;
@notification-type-icon-color: #6236ff;
@btn-primary-icon-color: #ffffff;
@warning-icon-color: #ffbd00;
@tooltip-icon-background-color: #ced0da;
@tooltip-icon-background-color-2: #e30613;
@scrollbar-thumb-background-color: #d5d7de;
@scrollbar-track-background-color: #e2e8f0;
@post-request-tag-color: #128474;
@get-request-tag-color: #961b2c;
@checked-switch-fill-color: #c6f6d5;

// Color Palette for new design system

@gray-25: #fcfcfd;
@gray-50: #f9fafb;
@gray-100: #f2f4f7;
@gray-200: #e4e7ec;
@gray-300: #d0d5dd;
@gray-400: #98a2b3;
@gray-500: #667085;
@gray-600: #475467;
@gray-700: #344054;
@gray-800: #1d2939;
@gray-900: #111619;

@red-25: #fffbfa;
@red-50: #fef3f2;
@red-100: #fee4e2;
@red-200: #fecdca;
@red-300: #fda29b;
@red-400: #f97066;
@red-500: #f04438;
@red-600: #e30613;
@red-700: #b42318;
@red-800: #7a271a;
@red-900: #330000;

@rose-100: #ffe4e8;
@rose-600: #e31b54;
@rose-700: #c01048;

@teal-25: #fafdfe;
@teal-50: #f3fafb;
@teal-100: #ecfafc;
@teal-200: #d9f4f8;
@teal-300: #9fdee4;
@teal-400: #1dbdcd;
@teal-500: #0098a5;
@teal-600: #137a84;
@teal-700: #0b5d66;
@teal-800: #14494e;
@teal-900: #083136;

@deep-ocean-teal-700: #123b47;

@yellow-25: #fffcf5;
@yellow-50: #fffaeb;
@yellow-100: #fef0c7;
@yellow-200: #fedf89;
@yellow-300: #fec84b;
@yellow-400: #fdb022;
@yellow-500: #f79009;
@yellow-600: #dc6803;
@yellow-700: #b54708;
@yellow-800: #93370d;
@yellow-900: #7a2e0e;

@green-25: #f6fef9;
@green-50: #ecfdf3;
@green-100: #d1fadf;
@green-200: #a6f4c5;
@green-300: #6ce9a6;
@green-400: #32d583;
@green-500: #12b76a;
@green-600: #039855;
@green-700: #027a48;
@green-800: #05603a;
@green-900: #054f31;

@blue-25: #f5faff;
@blue-50: #eff8ff;
@blue-100: #d1e9ff;
@blue-200: #b2ddff;
@blue-300: #84caff;
@blue-400: #53b1fd;
@blue-500: #2e90fa;
@blue-600: #1570ef;
@blue-700: #175cd3;
@blue-800: #1849a9;
@blue-900: #194185;

@purple-50: #f4f3ff;
@purple-500: #7a5af8;
@purple-600: #6938ef;
@purple-700: #5925dc;

@orange-50: #fff6ed;
@orange-600: #ec4a0a;

@snarky-mint-25: #f6fdf2;
@snarky-mint-200: #d9f8c9;
@snarky-mint-600: #a7ee82;
@snarky-mint-700: #96dd71;
@snarky-mint-1000: #567f41;

@near-moon-50: #eafbfb;

@black: #000000;
@white: #ffffff;

//Tokens

@gradient: linear-gradient(3.76deg, @teal-100 -0.32%, @teal-25 100%);
@gradient-gray-50: linear-gradient(91.07deg, @gray-50 47.33%, @white 106.58%);

//Brand
@bosta-red: @red-600;
@deep-teal: @teal-500;
@light-aqua: @teal-400;

//Text
@text-gray-dark: @gray-900;
@text-gray: @gray-500;
@text-gray-light: @gray-400;
@text-white: @white;
@text-teal: @teal-500;
@text-red: @red-600;
@text-red-light: @red-400;
@text-red-dark: @red-900;
@text-green-dark: @green-900;
@text-blue-dark: @blue-900;
@text-yellow-dark: @yellow-900;

//Background
@bg-gray-light: @gray-50;
@bg-gray: @gray-200;
@bg-gray-dark: @gray-800;
@bg-teal-light: @teal-50;
@bg-red-light: @red-100;
@bg-overlay-dark: rgba(@gray-500, 0.6);
@bg-default: @white;
@bg-hovered: @gray-50;
@bg-pressed: @gray-100;
@bg-selected: @teal-100;
@bg-disabled: @gray-100;

//Icons
@icon-default: @gray-500;
@icon-subdued: @gray-400;
@icon-disabled: @gray-300;
@icon-red: @red-400;
@icon-red-dark: @red-700;
@icon-green: @green-600;
@icon-teal: @teal-500;
@icon-yellow: @yellow-600;
@icon-green-dark: @green-600;
@icon-yellow-dark: @yellow-600;

//Borders
@border-all-sides: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200,
  inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-top-bottom: inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-right-left: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200;
@border-top: inset 0px 1px 0px @gray-200;
@border-bottom: inset 0px -1px 0px @gray-200;
@border-right: inset -1px 0px 0px @gray-200;
@border-left: inset 1px 0px 0px @gray-200;

//box-shadows
@shadow-xs: 0px 1px 2px rgba(@gray-800, 0.1);
@shadow-xs-green: 0px 1px 2px rgba(@gray-800, 0.1), 0px 0px 0px 4px @teal-200;
@shadow-xs-red: 0px 1px 2px rgba(@teal-200, 0.05), 0px 0px 0px 4px @red-100;
@shadow-sm: 0px 0px 1px 1px @gray-200, 0px 4px 4px rgba(@gray-800, 0.05);
@shadow-md: 0px 2px 4px rgba(@gray-800, 0.05), 0px 4px 16px rgba(@gray-800, 0.1);
@shadow-lg: 0px 0px 3px rgba(@gray-800, 0.1), 0px 4px 20px rgba(@gray-800, 0.15);
@shadow-xl: 0px 0px 4px rgba(@gray-800, 0.1), 0px 8px 40px rgba(@gray-800, 0.2);

//  Spacings
@None: 0;
@spacing-xs: 4px;
@spacing-sm: 8px;
@spacing-md: 12px;
@spacing-lg: 16px;
@spacing-xl: 24px;
@spacing-2xl: 32px;
@spacing-3xl: 48px;
@spacing-4xl: 56px;

//Break point
@2xs: 328px;
@xs: 576px;
@sm: 768px;
@md: 992px;
@lg: 1280px;
@xl: 1440px;
//just for reference
// 2xs: less than 328px
// xs: (328 <= xs < 576)
// sm: 576px (576 <= sm < 768)
// md: 768px (768 <= md < 992)
// lg: 992px (992 <= lg < 1280)
// xl: 1280 (1280 <= xl <= 1440)
// 2xl: More than 1440px;

// secondary colors
@blue-cascade: #637e8e;
