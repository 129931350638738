@import 'styles/variables';

.br-insurance-confirmation__container {
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  > button {
    width: 100%;
  }
}

.br-insurance-confirmation__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: @spacing-lg;
  margin-bottom: @spacing-xl;

  span:last-child {
    .font({.body()});
    color: @gray-500;
  }
}

.br-insurance-modal__info-title {
  text-align: center;
  margin-top: @spacing-xs;
  .font({.display-xs()});
}
