@import 'styles/variables';

.br-table-component {
  box-shadow: @shadow-sm;
  border-radius: 8px;
}

.br-table-component > .ant-btn {
  .br-button-component__label {
    width: 93%;
    justify-content: start;
    span:not(.br-order-details__header-product-name) {
      margin-right: 3px;
    }
    .br-order-details__header-product-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.br-table-component__empty-state {
  box-shadow: @border-all-sides;
}

.br-empty-state.br-table-component__empty-state {
  padding: 100px;
}

.br-table-component__no-selection {
  .br-table .ant-table-thead > tr > th:first-child {
    padding: 0 16px;
  }
  .br-table .ant-table-tbody > tr > td:first-child {
    padding: 8px 0 12px 16px;
  }
  .br-table__title {
    margin: 0 16px;
  }
}

.br-table-component__no-header.br-table-component {
  border-radius: 8px;
  .br-table.ant-table-wrapper,
  .ant-table.ant-table-scroll-horizontal,
  .ant-table {
    border-radius: 8px;
  }
  .ant-table-header.ant-table-sticky-holder {
    border-radius: 8px 8px 0 0;
  }
}

.br-table-component
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.br-table-component .ant-table-empty {
  min-height: calc(100vh - 280px);
  & .ant-table-body table {
    visibility: hidden;
  }
}

.br-table-component
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  background-color: unset;
}

.br-table .ant-table-thead > tr > th {
  border-bottom: 0;
}

.br-table .ant-table-content {
  border-top: 1px solid @gray-200;
}

.br-table {
  .ant-table .ant-table-cell {
    color: @text-gray-dark;
    .font({.body()});
  }
  ::-webkit-scrollbar {
    height: 16px !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: @gray-200;
    border-radius: 8px;
  }
}

.br-table .ant-table-container table > thead > tr:first-child th {
  background-color: @gray-50;
  text-transform: uppercase;
  color: @text-gray;
  box-shadow: @border-bottom;
  border-radius: 0;
  .font({.subheading()});
}

.br-table .ant-table-cell.ant-table-cell-fix-right-first {
  padding: 0;
  padding-left: 10px;
}

.br-table .ant-table-container table > thead > tr:first-child th {
  height: 40px;
}

.br-table .ant-table-thead > tr > th,
.br-table .ant-table-column-has-sorters {
  padding: 0px 12px;
}

.br-table .ant-table-column-sorters {
  padding: 0;
}

.br-table .ant-table-tbody > tr > td {
  padding: 8px 12px;
  height: 36px;
}

.br-table .ant-table-thead > tr > th:first-child,
.br-table .ant-table-tbody > tr > td:first-child {
  padding: 8px 0px;
}

.br-table .ant-table-thead > tr > th:nth-child(2),
.br-table .ant-table-tbody > tr > td:nth-child(2) {
  padding-left: 0px;
}

.br-table-row__cell {
  color: @text-gray-dark;
}

.br-table.ant-table-wrapper {
  border-radius: 0 0 8px 8px;
  box-shadow: @shadow-sm;
}

.br-table-component {
  .br-table__header__actions:not(.br-table__medium-mobile) {
    justify-content: flex-end;
  }
  .br-table__header__actions {
    margin-right: 16px;
    align-items: center;
  }

  .br-table__quick-filter-tabs {
    margin-bottom: 24px;
  }

  .ant-tabs-nav-wrap {
    margin-left: 16px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
    .font({.body()});
  }

  & .ant-tabs-tab {
    color: @text-gray;
    padding: 16px;
  }
  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @text-gray-dark;
    .font({.body-medium()});
  }

  & .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    background-color: @bosta-red;
    padding-top: 4px;
    border-radius: 5px 5px 0px 0px;
  }
}

.br-table-component .ant-table {
  border-radius: 0;
  .ant-table-sticky-scroll {
    display: none;
    height: 16px;
    border-top: 1px solid @gray-200;
    padding: 8px;
    opacity: 1;
  }

  .ant-table-sticky-scroll-bar {
    background-color: @gray-200;
    border-radius: 8px;
    height: 8px;
  }
}

.br-table .ant-table-tbody .ant-btn.ant-dropdown-trigger.br-table__actions {
  width: 36px;
  height: 28px;
  padding: 0px;
  svg {
    margin: 0;
  }
}

.br-table-component
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.br-table .ant-table-row.ant-table-row-level-0:hover,
.br-table .ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: @bg-hovered;
  & .ant-btn {
    box-shadow: @shadow-xs;
    border: 1px solid @gray-300;
    height: 28px;
  }
}

.br-table
  .ant-table-thead
  .ant-checkbox.ant-checkbox-indeterminate
  .ant-checkbox-inner {
  background-color: @teal-500;
  border: 1px solid @teal-500;
}

.br-table
  .ant-table-thead
  .ant-checkbox-indeterminate
  .ant-checkbox-inner::after {
  background-color: white;
  height: 1px;
  width: 8px;
  border-color: white;
}

.br-table .ant-pagination-options {
  .ant-select-selector {
    padding: 0;
  }
  .ant-select-dropdown {
    padding: 8px 0;
  }
  & .ant-select-item-option-active {
    background-color: @gray-100;
  }
  & .ant-select-item-option-selected {
    background-color: @white;
  }
  & .ant-select-item:not(.ant-select-item-option-selected) {
    margin: 0 8px;
  }
  & .ant-select-item-option-selected {
    margin-right: 8px;
    .ant-select-item-option-content {
      margin-left: 4px;
    }
  }
}

.br-table .ant-pagination-options {
  & .ant-select-item-option-active,
  .ant-select-item-option-selected {
    border-radius: 4px;
  }

  & .ant-select-item-option-content {
    display: flex;
    align-items: center;
    color: @text-gray-dark;
    padding: 8px;
  }

  & .ant-select-item-option-selected {
    border-left: 4px solid @teal-500;
  }

  & .ant-select-item-option-selected .ant-select-item-option-content {
    background-color: @bg-selected;
    border-radius: 4px;
    color: @text-gray-dark;
  }
  & .ant-select-item {
    padding: 0;
    margin-bottom: 4px;
  }
  .ant-select-item-option-content {
    margin-bottom: 0;
  }
}

.br-table .ant-pagination-options .ant-select-dropdown {
  width: 160px;
}

.br-table__title {
  margin-left: 20px;
  color: @text-gray-dark;
  white-space: nowrap;
  margin-right: 5px;
  flex: 1;
}

.br-table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.br-table__fixed-header .ant-table-header table {
  background-color: @bg-gray-light;
  height: 52px;
  display: flex;
  align-items: center;
  box-shadow: @shadow-sm;
  border: 1px solid @gray-200;
  & .ant-table-cell {
    background-color: @bg-gray-light;
    padding-left: 12px;
  }
  & .ant-btn {
    margin-left: 8px;
  }
  & colgroup {
    display: none;
  }
}

.br-table__medium-mobile {
  height: 100%;
  & .br-table__header__actions {
    justify-content: flex-end;
    margin-right: 12px;
    & .br-table__header__input-search {
      width: 190px;
    }
    & .ant-btn {
      width: 36px;
    }
    & .ant-btn svg {
      margin-right: 0;
    }
  }
}

.br-table__small-mobile {
  & .br-table__quick-filter-tabs {
    margin: 0 0 8px 0;
  }
  // & .br-table__header__input-search {
  //   padding: 0 16px;
  //   margin-top: 12px;
  //   margin-bottom: 8px;
  // }

  & .br-table__title {
    margin-left: 16px;
  }

  & .br-table__header__actions {
    justify-content: flex-end;
    margin-right: 12px;
    & .ant-btn {
      width: 44px;
    }
    & .ant-btn svg {
      margin-right: 0;
    }
  }
}

.br-table__header__input-search,
.br-table__header__input-search .ant-input-affix-wrapper {
  height: 36px;
}

.br-table__header__input-search .ant-input-affix-wrapper {
  padding: 0 12px;
}

.br-table__header__input-search:not(
    .br-table__header__input-search--active
  ):not(.br-table__header__input-search--active__no-shadow)
  .ant-input-group-addon {
  display: none;
}

.br-table__header__input-search--active:not(
    .br-table__header__input-search--active__no-shadow
  )
  .ant-input-group-addon {
  border: 1px solid @teal-300;
  box-shadow: @shadow-xs-green;
}

.br-table__header__input-search--active.ant-input-search,
.br-table__header__input-search--active__no-shadow.ant-input-search {
  & .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    clip-path: inset(-10px 0px -10px -11px);
  }
}

.br-table__header__input-search--active,
.br-table__header__input-search--active__no-shadow {
  & .ant-input-search-button {
    background-color: @teal-500;
    border: 1px solid @teal-500;
    box-shadow: @shadow-xs-green;
    clip-path: inset(-10px -10px -10px 0px);
    border-radius: 0px 4px 4px 0px;
    height: 36px;
    & .anticon svg {
      color: @white;
    }
  }
}

html[dir='rtl'] .br-table__header__input-search--active,
.br-table__header__input-search--active__no-shadow {
  & .ant-input-search-button {
    clip-path: inset(-10px 0px -10px -10px);
  }
}

html[dir='rtl'] .br-table__header__input-search--active.ant-input-search,
.br-table__header__input-search--active__no-shadow.ant-input-search {
  & .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    clip-path: inset(-10px -10px -10px 0px);
  }
}

.br-table__header__input-search--active
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-radius: 0px 4px 4px 0px;
}
.br-table__header,
.br-table__header__actions {
  display: flex;
}

.br-table__header__actions {
  flex: 2;
  & > .ant-btn {
    margin-left: 8px;
  }
}

.br-table__loading {
  color: @gray-900;
  white-space: nowrap;
  margin-top: 20px;
  width: fit-content;
}

.br-table {
  .ant-spin-spinning svg {
    font-size: 42px;
    color: @teal-500;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    left: 50%;
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.br-table__header__input-search--active__no-shadow
  .ant-input-affix-wrapper-focused {
  box-shadow: unset;
  border-color: @gray-300;
}

.br-table__header__input-search--active__no-shadow .ant-input-search-button,
.br-table__header__input-search--active__no-shadow
  .ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.br-table__header__actions__active-filters.ant-btn {
  border-color: @teal-500;
  svg {
    margin-right: 0;
    path {
      fill: @teal-500;
    }
  }
}

.br-table-filters__tags {
  background: @gray-200;
  border-radius: 12px 0px 0px 12px;
  padding: 0 8px;
}

.br-table-filters__all-tags {
  margin-bottom: 16px;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 8px;
    margin-bottom: 8px;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.br-table-filters__tags--close-icon {
  background: @gray-200;
  border-radius: 0px 40px 40px 0px;
  margin-left: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .anticon {
    padding: 4px 7px 4px 4px;
  }
}

.br-table-filters__tags--close-icon:hover {
  background: @gray-300;
}

.br-table-filters__tags--title {
  color: @text-gray;
  margin-right: 4px;
}

.br-table-filters__tags--content {
  color: @text-gray-dark;
}

.br-table-filters__extra-tags__label {
  background-color: @gray-100;
  padding: 0px 6px;
  border-radius: 16px;
  margin-left: 4px;
  color: @text-gray;
}

.br-table-filters__extra-tags .ant-tooltip-inner {
  text-align: start;
}

.br-table__small-mobile .ant-tabs-nav-wrap {
  margin-left: 0px;
  .ant-tabs-tab:first-child {
    margin-left: 16px;
  }
}

.br-table__header__input-search {
  .ant-input-search-button:hover {
    background: @teal-500;
    .anticon-search svg path {
      fill: @white;
    }
  }
}

.br-table-component
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent;
}

.br-table__actions .anticon {
  font-size: 20px;
}

.br-table-component
  .ant-table-row:not(.ant-table-row-selected)
  .br-table__actions.ant-btn.ant-dropdown-trigger {
  border: 1px solid @white;
  box-shadow: none;
  & .anticon {
    font-size: 20px;
    width: 20px;
  }
}

.br-table-component
  .ant-table-row-selected
  .br-table__actions.ant-btn.ant-dropdown-trigger {
  background: transparent;
  border: none;
  box-shadow: none;
}

.br-table-component
  .ant-table-row
  .br-table__actions.ant-btn.ant-dropdown-trigger:hover {
  border: 1px solid @gray-300;
  box-shadow: 0px 1px 2px rgba(@gray-800, 0.1);
}

.br-table-component
  .br-table-mobile-card
  .br-table__actions.ant-btn.ant-dropdown-trigger:hover {
  border: 1px solid @teal-300;
  box-shadow: @shadow-xs-green;
}

.br-table-component .ant-dropdown-menu-title-content > span {
  display: block;
  width: 100%;
}

.br-table-filter__menu-content .ant-checkbox-wrapper {
  width: 100%;
}

.br-table__small-mobile .br-empty-state__sub-title {
  text-align: center;
  max-width: 297px;
}

.br-table__small-mobile
  .ant-input-group-addon
  .ant-btn-icon-only.ant-btn.ant-btn-default {
  width: 44px;
}

.br-table-pagination-prev-icon,
.br-table-pagination-next-icon {
  border: 1px solid @gray-300;
  box-shadow: @shadow-xs;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

html[dir='rtl'] .br-table-pagination-prev-icon,
html[dir='rtl'] .br-table-pagination-next-icon {
  transform: scaleX(-1);
}

.ant-pagination-disabled {
  .br-table-pagination-prev-icon,
  .br-table-pagination-next-icon {
    background: @gray-50;
    border: 1px solid @gray-200;
    svg path {
      fill: @gray-300;
    }
  }
}

.br-table
  .ant-pagination-options
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 36px;
  width: 108px;
  display: flex;
  align-items: center;
  border: 1px solid @gray-300;
  box-shadow: @shadow-xs;
  .ant-select-selection-item {
    display: flex;
    justify-content: center;
    .font({.button()});
  }
}

.br-table__empty-field {
  color: @text-gray-light;
}

.br-table__header__input-search-by.ant-select.ant-select-single:not(
    .ant-select-customize-input
  ) {
  max-width: 128px;

  .ant-select-selector {
    background-color: @gray-50;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-select-selection-item {
    padding: 0;
  }

  &.ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
    background: transparent url('../../assets/bosta-icons/Dropdown.svg')
      no-repeat 50% 50%;
  }
}

.br-table__header__input-search-by-active {
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.br-table__header__input-search-container {
  display: flex;
  justify-content: flex-end;
  flex: 2;
}

html[dir='rtl']
  .br-table__header__input-search-by.ant-select.ant-select-single:not(
    .ant-select-customize-input
  ) {
  .ant-select-selection-item {
    span {
      text-overflow: unset;
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-table-component .ant-pagination-options {
    display: block;
  }

  .br-table__header__input-search-container {
    min-width: auto;
    padding: 0 16px;
    margin-top: 12px;
  }
}

.mobile-card-drop-over-downward,
tr.drop-over-downward td {
  border-bottom: 2px dashed @teal-500;
}

.mobile-card-drop-over-upward,
tr.drop-over-upward td {
  border-top: 2px dashed @teal-500;
}

@media only screen and(max-width:@sm) {
  .br-table-component {
    border-radius: 0;
  }

  .br-table-mobile-card {
    border-radius: @spacing-sm;
    margin-bottom: @spacing-lg;
  }

  .br-table__header {
    padding: @spacing-lg;

    .br-table__title,
    .br-table__header__actions {
      margin: 0;
    }
  }

  .br-table__date-filter {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: @spacing-lg;
    padding-top: 0;

    .ant-picker.ant-picker-range {
      width: 100%;
    }
  }
}
