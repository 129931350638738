@import 'styles/variables';

.br-pickups-cancelled-pickup__banner {
  margin-bottom: 8px;

  .br-banner-content:not(.br-banner-fixed) .br-banner-content__inner-container {
    padding: 0;
  }

  .br-banner-content__text-container {
    justify-content: flex-start;

    & > svg {
      width: 30px;
    }
  }
}

.br-pickups-cancelled-pickup__banner-count {
  color: @yellow-600;
}

@media only screen and (max-width: @sm) {
  .br-pickups-cancelled-pickup__banner {
    .br-banner-content__text .br-banner-content__text-subtitle {
      margin-top: 0;
    }
  }
}
