@import 'styles/variables.less';

.br-address-details {
  .ant-col.ant-form-item-label {
    display: none;
  }
  .br-form-row {
    margin-bottom: 8px;
  }

  .br-form-row:first-of-type {
    margin: 4px 0 8px 0;
  }

  .br-form-row:nth-of-type(2) {
    flex-direction: row;
    .ant-form-item {
      max-width: 164px;
    }
  }

  .br-forms-new-input {
    min-height: unset;
    .ant-row.ant-form-item {
      min-height: unset;
    }
    .ant-form-item-explain {
      margin-bottom: 0;
    }
  }
}

.br-address-details__form-title {
  color: @text-gray-dark;
}

@media only screen and (max-width: @md) {
  .br-location-form .br-form-row > *:not(:last-child) {
    margin-right: unset;
  }

  .br-form-row:nth-of-type(2) {
    gap: 8px;
  }
}
