@import 'styles/variables.less';

.br-not-found-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.br-not-found-container .br-not-found-container__logo svg {
  height: 60px;
  width: 176px;
}

.br-not-found-container .br-not-found-container__not-found svg {
  height: 160.24px;
  width: 400px;
  margin-top: 51px;
  margin-bottom: 53px;
  max-width: 100%;
}

.br-not-found-container .br-content-header__titles {
  text-align: center;
}

.br-not-found-container div .br-sidebar-content__title {
  font-size: 24px;
  font-family: @font-500;
}

.br-not-found-container div .br-sidebar-content__sub-title {
  font-size: 18px;
}

.br-not-found-container div div.br-sidebar-content-header-with-sub-title {
  margin-bottom: 18px;
}

.br-not-found-container .br-sidebar-content__sub-title {
  justify-content: center;
}
