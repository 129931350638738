@import 'styles/variables';

.br-order-destination__selector {
  display: flex;
  flex: 1;
  border: 1px solid @gray-300;
  border-radius: 4px;
  box-shadow: @shadow-xs;
  padding: 8px 14px;
  gap: 12px;
  background-color: @white;

  > img {
    max-width: 32px;
  }
}

.br-order-destination__info {
  display: flex;
  flex-direction: column;
}

.br-order-destination__origin {
  color: @text-gray;
  text-transform: uppercase;
}

.br-order-destination__selector-active {
  cursor: pointer;
}
