@import 'styles/variables';

.br-signup-pricing-table-header__container {
  display: flex;
  flex-direction: column;

  > span {
    text-transform: none;
    color: @text-gray-dark;
  }

  > span:last-child {
    color: @text-gray;
    .font({.body-medium()});
  }

  &.br-signup-pricing-table-header {
    > span:last-child {
      color: @text-gray;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;

      .font({.caption()});
    }
  }
}
