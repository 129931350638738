@import 'styles/variables';

.br-select.ant-select {
  width: 100%;
  .ant-select-item {
    padding: 0;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
      .ant-select-item-option-selected
    ) {
    background-color: transparent;

    .br-select-highlighter {
      background-color: @bg-hovered;
    }
  }

  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
  .ant-select-item-option-selected {
    background-color: unset;

    .br-selected-indicator {
      visibility: visible;
    }

    .br-select-highlighter {
      padding: 8px;
      background-color: @teal-100;
      width: calc(100% - 16px);
      border-radius: 4px;
    }
  }

  .br-select-highlighter {
    padding: 8px;
    width: calc(100% - 16px);
    border-radius: 4px;
  }
}

.ant-select-item-option-content .br-selected-indicator {
  visibility: hidden;
  display: block;
  background: @deep-teal;
  width: 4px;
  min-height: 36px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
}

.br-select-dropdown__title {
  color: @text-gray;
  padding: 12px;
  height: 24px;
  text-transform: uppercase;
  pointer-events: none;
}

.br-select-dropdown__title ~ .rc-virtual-list {
  margin-top: 8px;
}
