@import 'styles/variables';

.br-uncovered-zones-list__container {
  max-height: 300px;
  overflow: auto;
}

.br-uncovered-zones-list__item {
  box-shadow: @border-bottom;
  padding: 12px 16px;
}

.br-uncovered-zones-list__city {
  color: @text-gray-dark;
  text-transform: uppercase;
}

.br-uncovered-zones-list__district-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  color: @text-gray;

  .br-tag {
    text-transform: uppercase;
  }
}

.br-uncovered-zone-list__district-name {
  display: flex;
  align-items: center;
  gap: 10px;

  > span {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: @icon-yellow-dark;
  }
}
