.br-security_otp-screen {
  .br-security_otp-screen__subtitle {
    margin-bottom: 20px;
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-input-number-affix-wrapper,
  .ant-input-number {
    width: 100%;
    border-radius: 8px;
  }

  .ant-input-number-affix-wrapper {
    min-height: 36px;

    input {
      margin-left: 8px;
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-row.ant-form-item {
    min-height: 67px;
  }
}
