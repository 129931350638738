@import 'styles/variables';

.br-suggestion-box__button.ant-btn {
  position: absolute;
  right: 0;
  top: 45%;
  z-index: 9;
  transform-origin: top right;
  transform: rotate(-90deg) translateY(-100%);

  svg > * {
    stroke: @teal-500;
  }
}
