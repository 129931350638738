@import 'styles/variables';

.br-city-area__no-character-search,
.br-city-area__no-results-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 48px;
}
.br-city-area__no-results-search span {
  color: @text-gray-dark;
}

.br-city-area__city-name {
  display: none;
}

.br-city-area__dropdown {
  display: flex;
  justify-content: space-between;
}

.br-city-area-city-group__expanded {
  background: @bg-pressed;
  border-radius: 4px;
  svg {
    transform: rotate(180deg);
  }
}

.br-city-area .ant-select-item.ant-select-item-option {
  border-radius: 4px;
  padding: 0 8px 0 0;
}

.br-city-area
  .ant-select-item.ant-select-item-option:not(.ant-select-item-option-selected) {
  margin-left: 8px;
}

.br-city-area__highlighted-text {
  color: @teal-500;
  background-color: @bg-selected;
  padding: 0;
}

.br-city-area {
  .ant-select-item-option-state {
    display: none;
  }
  .br-uncovered-area {
    color: @text-gray-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .ant-tag.br-city-area__uncovered-tag {
    height: fit-content;
    margin-right: 0;
    border-radius: 16px;
    text-transform: uppercase;
    .font({.subheading()});
  }
  .br-city-area__content {
    width: 100%;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-grouped.ant-select-item-option-active {
    background-color: @white;
    .ant-select-item-option-content {
      background-color: @bg-hovered;
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white;
    .br-city-area__selected-indicator {
      background: #0098a5;
      display: block;
    }
    span:nth-child(2) {
      padding: 8px 0px 8px 8px;
      background-color: @bg-selected;
      border-radius: 4px;
      width: 100%;
    }
  }
  .ant-select-item-option:not(.ant-select-item-option-selected)
    .ant-select-item-option-content {
    display: flex;
    align-items: center;
    padding: 8px;
  }
}

.br-city-area__selected-indicator {
  width: 4px;
  height: 36px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
  display: none;
}

.br-city-area .ant-spin {
  padding: 60px 0;
}

.br-form-city-area {
  .rc-virtual-list-scrollbar {
    width: 12px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    left: 1px !important;
    background-color: @gray-200 !important;
    overflow: hidden;
  }
  .rc-virtual-list-scrollbar-show {
    background-clip: padding-box;
    background-color: @gray-50;
    display: block !important;
  }
}

html[dir='rtl'] .br-form-city-area {
  .rc-virtual-list-scrollbar {
    right: 0px !important;
    left: unset !important;
  }
}

.br-city-area__btn.ant-btn {
  width: 100%;
  justify-content: flex-start !important;
  height: 36px;

  &:hover {
    color: initial;
  }

  .br-button-component__label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .br-city-area__placeholder {
      color: @gray-300;
    }

    .br-city-area__city-name {
      display: block;
      color: @text-gray;
    }
    .br-city-area__area-name {
      margin-right: 8px;
    }
  }
}

.ant-form-item-has-error .br-city-area__btn.ant-btn:active {
  border-color: @red-100;
}

.br-city-area__field {
  display: flex;
  align-items: center;

  .br-city-area__area-name {
    margin-right: 8px;
  }
}

.br-form-city-area__dimmed .br-city-area__btn {
  background-color: @bg-gray-light;
  border-color: @bg-gray;

  cursor: not-allowed;
  &:hover {
    background-color: @bg-gray-light;
    border-color: @bg-gray;
  }
}
