@import 'styles/variables';

.br-incomplete-location__banner {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  box-shadow: @shadow-sm;
  border-radius: 4px;
}

.br-inomplete-location__alert-text {
  color: @icon-yellow-dark;
}

.br-incomplete-location__modal {
  .ant-modal-content {
    width: 372px;
  }

  .ant-modal-body {
    padding: 24px 20px;
  }

  .br-incomplete-location-modal__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    > * {
      width: 100%;
    }

    > .anticon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: @gray-100;
    }
  }
}

.br-incomplete-location-modal__title {
  text-align: center;
}

.br-incomplete-location__banner__location {
  display: flex;
  align-items: center;
  gap: 4px;

  > .anticon > svg > * {
    fill: @icon-yellow-dark;
  }
}
