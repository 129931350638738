@import 'styles/variables';

.br-signup-banner__container {
  display: flex;
  flex-direction: column;
  flex: 40%;
  gap: 40px;
  padding: 40px;
  background: linear-gradient(3.76deg, @teal-100 -0.32%, @teal-25 100%);
  position: relative;
  z-index: 1;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }
}

.br-signup-banner__container .highlighted {
  color: @text-red;
}

.br-signup-banner__title {
  text-align: center;
}

.br-signup-banner__pricing-calculator {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  background-color: @white;
}

.br-signup-banner__carousel {
  display: flex;
  justify-content: center;
  max-width: 100%;

  .ant-carousel {
    width: 404px;

    .slick-list .slick-slide {
      pointer-events: auto;
    }

    .slick-dots {
      bottom: -30px;

      & > li > button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: @gray-400;
      }

      li.slick-active button {
        background-color: @gray-500;
      }

      & li,
      & li.slick-active {
        width: fit-content;
      }
    }

    iframe {
      min-height: 240px;
      z-index: 999999;
    }
  }
}

.br-signup-banner__pricing-title {
  color: @text-gray;
}

.br-signup-banner__features {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.br-signup-banner__feature-item {
  display: flex;
  align-items: flex-start;
  width: 48%;
  gap: 8px;
}

@media only screen and (max-width: @sm) {
  .br-signup-banner__container {
    padding: 24px;
  }

  .br-signup-banner__pricing-calculator {
    align-items: center;
  }

  .br-signup-banner__feature-item {
    width: 45%;
  }
}
