@import 'styles/variables';

.br-smart-sticker-guide__modal {
  max-height: calc(100% - 100px);
  .ant-modal-body {
    padding: 0;
  }
}

.br-smart-sticker-guide-modal__container {
  border-radius: 8px;
}

.br-smart-sticker-guide-modal__header {
  display: flex;
  justify-content: space-between;
  padding: 44px 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: url('../../assets/imgRevamp/SmartStickerHeader.svg'), @teal-100;
  background-position: center;
  
  & > svg {
    cursor: pointer;
  }
}

.br-smart-sticker-guide-modal__header-title {
  max-width: 452px;

  > span {
    background-color: @teal-600;
    background-image: linear-gradient(45deg, @teal-600, @teal-400);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

.br-smart-sticker-guide-modal__body {
  display: flex;
  justify-content: space-between;
  padding: 32px;
}

.br-smart-sticker-guide__steps {
  .ant-timeline-item-head {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: @text-teal;
    background-color: @bg-teal-light;
    .font({.body-medium});

    & > svg > * {
      fill: @text-teal;
    }
  }

  .ant-timeline-item-content {
    margin-left: 32px;
  }

  .ant-timeline-item {
    padding-bottom: 28px;
  }
}

.br-smart-sticker-guide__step-subtitle {
  color: @text-gray;
  margin-top: 4px;

  & > span {
    color: @text-gray-dark;
  }
}

span.br-smart-stickers-guide__teal-text {
  color: @text-teal;
}

.br-smart-sticker-guide__step-banner {
  margin-top: 12px;

  .br-banner-content:not(.br-banner-fixed) .br-banner-content__inner-container {
    padding: 0;
  }

  .br-banner-content__text-subtitle {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.br-smart-sticker-guide__footer {
  padding: 32px;
  padding-top: 0;

  .ant-btn {
    width: 100%;
  }
}

.br-smart-sticker-guide-modal__container {
  .ant-timeline-item-tail {
    border-left: 2px solid @bg-teal-light;
  }
}

@media only screen and (max-width: @sm) {
  .br-smart-sticker-guide-modal__body {
    flex-direction: column;
    padding: 16px;
    .br-banner-content__text .br-banner-content__text-subtitle {
      margin-top: 0;
      flex-wrap: wrap;
    }
  }

  .br-smart-sticker-guide__steps {
    order: 2;
    margin-top: 40px;
    padding: 0 10px;
  }

  .br-smart-sticker-guide__video-container {
    order: 1;
  }

  .br-smart-sticker-guide-modal__header-title {
    max-width: 240px;

    & > span {
      display: inline-flex;
    }
  }

  .br-smart-sticker-guide__footer {
    padding: 16px 16px 32px 16px;
    background-color: @bg-gray-light;
    box-shadow: @border-top;
  }
}
