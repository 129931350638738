@font-face {
  font-family: 'Poppins/Regular';
  src: url(../assets/fonts/English/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins/SemiBold';
  src: url(../assets/fonts/English/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Poppins/Medium';
  src: url(../assets/fonts/English/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'Lato/SemiBold';
  src: url(../assets/fonts/English/Lato-Semibold.ttf);
}

@font-face {
  font-family: 'Lato/Regular';
  src: url(../assets/fonts/English/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Lato/Bold';
  src: url(../assets/fonts/English/Lato-Bold.ttf);
}

/* rtl fonts */
@font-face {
  font-family: 'Cairo/Bold';
  src: url(../assets/fonts/Arabic/Cairo-Bold.ttf);
}

@font-face {
  font-family: 'Cairo/SemiBold';
  src: url(../assets/fonts/Arabic/Cairo-SemiBold.ttf);
}

@font-face {
  font-family: 'Cairo/Regular';
  src: url(../assets/fonts/Arabic/Cairo-Regular.ttf);
}

@font-face {
    font-family: 'Cairo/Medium';
    src: url(../assets/fonts/Arabic/Cairo-Medium.ttf);
  }
  