@import 'styles/variables';

.br-prohibited-items-list__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  max-height: 300px;
  overflow: auto;
}

.br-prohibited-items-list__item {
  display: flex;
  align-items: center;
  gap: 16px;

  > svg {
    width: 24px;
    height: 24px;
  }
}

.br-prohibited-items-list__item-subtitle {
  color: @text-gray;
}
