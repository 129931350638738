@import 'styles/variables';

@keyframes reveal {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.br-city-area-collapse__suggestions-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px 16px;
  overflow: hidden;
  white-space: nowrap;

  .ant-divider {
    margin: 0 12px;
    height: 20px;
  }
}

.br-city-area-collapse__suggestions-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: @text-teal;
  width: fit-content;
}

.br-city-area-collapse__suggestions-list {
  display: flex;
  align-items: center;
  gap: 8px;
  animation: reveal 2s ease-in-out forwards;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.br-city-area-collapse__suggestions-item {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border: 1px dashed @gray-400;
  border-radius: 16px;
  cursor: pointer;
  scroll-snap-align: start;

  &:hover {
    background-color: @bg-hovered;
  }

  > svg {
    width: 12px;
    height: 12px;

    > * {
      fill: @icon-teal;
    }
  }
}

.br-city-area-collapse__suggestions-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-city-area-collapse__suggestions-arrows {
  display: flex;
  align-items: center;
  gap: 12px;

  > svg {
    cursor: pointer;

    &.is-disabled {
      cursor: not-allowed;

      > * {
        stroke: @icon-disabled;
      }
    }
  }
}

html[dir='rtl'] .br-city-area-collapse__suggestions-arrows {
  > svg {
    transform: scale(-1);
  }
}
