@import 'styles/variables.less';

.ant-modal.br-cashout-guide-modal {
  width: 100%;
  max-width: 608px;

  & .ant-modal-header {
    & .ant-modal-title {
      width: 100%;
    }

    & .br-cashout-guide-modal__header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      & svg {
        cursor: pointer;
      }
    }
  }
}

.br-cashout-guide-modal__main-content-container {
  background: @teal-50;
  border-radius: 8px;
  padding: 20px;

  & > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-bottom: 1px solid transparent;

    & span {
      color: @teal-500;
      border-bottom: 1px dashed @teal-500;
      cursor: pointer;
    }

    & svg {
      background-color: white;
      border-radius: 50%;
      margin-left: 8px;
      cursor: pointer;
    }
  }
}

.br-cashout-guide__menu-overlay-container {
  background-color: white;
  display: flex;
  flex-direction: column;

  & > div {
    padding: 10px 16px;
    cursor: pointer;

    & span {
      color: @gray-900;
      border: none;
    }

    transition: all 0.3s;
  }

  & > div:hover {
    background-color: @teal-50;
  }
}

.br-cashout-guide__option-dropdown {
  width: 100%;
  max-width: 132px;
}

.br-cashout-guide-modal__plan-info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.br-cashout-guide-modal__plan-info-content {
  border: 1px solid @gray-200;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.br-cashout-guide__number-container {
  width: 100%;
  max-width: 32px;
  height: 32px;
  background: @gray-50;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @gray-500;
}

html[dir='rtl'] .br-cashout-guide__main-info-container {
  & > div:last-of-type {
    & > div:first-of-type {
      & svg {
        transform: scale(-1);
      }
    }
  }
}

.br-cashout-guide__main-info-container {
  display: flex;
  flex-direction: column;

  & > div:first-of-type {
    margin: 12px 0;
    display: flex;
    gap: 4px;
  }

  & > div:last-of-type {
    display: flex;
    align-items: center;

    & svg.br-cashout-guide-tooltip-icon {
      width: 12px;
      height: 12px;
    }

    & > span.heading:last-of-type {
      margin-right: 6px;
      text-align: center;
    }

    & > div:first-of-type {
      margin-right: 8px;
      white-space: nowrap;

      & span {
        background: @teal-100;
        border-radius: 16px;
        padding: 4px 10px;
        color: @teal-700;
      }
    }
    & > div:last-of-type {
      margin: 0 8px;
      border: 1px solid @teal-500;
      border-radius: 4px;
      padding: 4px 8px;
      text-align: center;

      & span {
        color: @teal-500;
      }
    }
  }
}

.br-cashout-guide-modal__day-container {
  border: 1px solid @gray-200;
  border-radius: 4px;
  padding: 4px 8px;
}

@media only screen and (max-width: @xs) {
  .br-cashout-guide-modal__plan-info-content {
    padding: 20px 6px;
  }
}
