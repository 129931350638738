@import 'styles/variables';

.ant-modal-wrap.br-video-modal .ant-modal {
  width: 100%;
  max-width: 940px;
  height: 528px;
}

.br-video-modal .ant-modal-body {
  padding: 0;
  width: 100%;
  height: 528px;
}

.br-video-modal__iframe {
  width: 100%;
  height: 100%;
}

.br-video-modal__close {
  position: absolute;
  z-index: 9999;
  top: 32px;
  right: 32px;
  cursor: pointer;

  svg path {
    fill: @white;
    stroke: @white;
  }
}
