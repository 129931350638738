@import 'styles/variables';

.br-insurance-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.ant-modal-centered .ant-modal.br-insurance-modal-container {
  display: contents;

  .ant-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
}

.br-insurance-modal__content {
  display: flex;
  flex-direction: column;
  padding: @spacing-xl;
  gap: @spacing-md;
  max-width: 410px;
  width: 100%;
}

@media only screen and (max-width: @sm) {
  .br-insurance-modal__content {
    max-width: 340px;
    padding: 20px;
  }
}
