@import 'styles/variables';

.verify-email-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-email-parent {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex: 1 1 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.verify-email-right-side {
  display: flex;
  vertical-align: top;
  background: @background-color;
  overflow: auto;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
}

.email-correct-img {
  margin-bottom: 30px;
}

.verify-email-green-label {
  color: @text-color-29;
  font-family: @font-600;
}

.verify-email-right-content {
  padding: 118px 340px 0 340px;
}

.verify-email-small-label {
  font-size: 16px;
  margin-bottom: 10px;
}

.verify-email-resend-margin {
  margin-bottom: 36px;
  margin-top: 18px;
}

.verify-email-heading {
  font-weight: 600;
}

.verify-email-right-content .verify-email-btn-skip {
  height: 48px;
  width: 130px;
  border-radius: 8px;
  border: 1px solid @border-color-7;
  border-radius: 8px;
  background-color: @background-color;
  margin-top: 30px;
}

.verify-email-btn-skip span {
  color: @text-color-11;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  font-family: @font-600;
}

.verify-email-right-side .ant-typography + h2.ant-typography,
.verify-email-right-side h2.ant-typography,
.verify-email-right-side .ant-typography + h2.ant-typography {
  margin-top: unset;
  margin-bottom: unset;
  font-size: 28px;
  font-family: @font-600;
  word-break: break-word;
}

.verify-phone-red-label {
  text-underline-position: below;
  color: @text-color-27;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 20px;
}

.bosta-logo-img {
  display: none;
}

.verifiy_email_img_background {
  display: none;
}

.verify-email-side-responsive {
  flex-basis: 100%;
  overflow: unset;
}

.verify-email-content-image,
.verify-email-content-bottom-image img {
  display: none;
}

.verify-email-content-image-responsive {
  display: flex;
  justify-content: center;
  align-content: center;
}

.verify-email-content-bottom-image_responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 175px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .verify-email-content-image {
    .verify-email-content-image-responsive();
  }

  .verify-email-content-bottom-image img {
    .verify-email-content-bottom-image_responsive();
  }

  .verify-email-content-bottom-image {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background-color: #f9f9f9;
  }

  .verify-email-right-side {
    padding-top: 45px;
    flex-basis: 100%;
    overflow: unset;
  }

  .verify-email-right-content {
    margin-left: 0px;
    margin-top: 70px;
    width: 100%;
    padding: 0 20px;
  }

  .verify-email-right-content-text {
    padding-left: 10px;
  }

  .verify-email-right-content .verify-email-btn-skip {
    width: 100%;
  }

  .verify-phone-resend-margin.ant-typography {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  .verify-email-right-side .ant-typography + h2.ant-typography,
  .verify-email-right-side h2.ant-typography,
  .verify-email-right-side .ant-typography + h2.ant-typography {
    font-size: 24px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .verify-email-right-content {
    align-items: center;
    padding-top: 40px;
    .verify-email-side-responsive();
  }

  .verify-email-right-side {
    padding-top: 70px;
    align-items: center;
    .verify-email-side-responsive();
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .verify-email-content-image {
    .verify-email-content-image-responsive();
  }

  .verify-email-content-bottom-image img {
    .verify-email-content-bottom-image_responsive();
  }

  .verify-email-content-bottom-image {
    width: 100%;
    background-color: #f9f9f9;
  }

  .verify-email-right-side {
    padding-top: 75px;
    flex-basis: 100%;
    overflow: unset;
  }

  .verify-email-right-content {
    margin-left: 0px;
    margin-top: 40px;
    width: 100%;
    padding: 0 140px;
  }

  .verify-email-right-content-text {
    padding-left: 10px;
  }

  .verify-phone-resend-margin.ant-typography {
    margin-bottom: 128px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1920px) {
  .verify-email-right-side {
    padding: 0;
  }

  .verify-email-right-content {
    width: 100%;
    margin: 0;
    padding: 157px 70px 0 70px;
  }

  .verify-email-right-content-text {
    padding-left: 10px;
  }
}
