@import 'styles/variables';

.br-signup .br-sidebar-content__sub-title {
  margin-top: 8px;
  .ant-btn {
    display: unset;
    height: unset;
    width: unset;
    padding: 2px;
  }
}

.br-signup .ant-form.ant-form-horizontal .br-signup__form__hear-about-us {
  margin-bottom: 16px;
}

.br-signup .ant-row.ant-form-item {
  min-height: unset;
}

.br-signup .ant-input-group .ant-row.ant-form-item {
  min-height: 0;
  margin: 0;
}

.br-signup__form__bosta-logo {
  display: none;
  width: fit-content;
  align-self: center;
}

.br-signup .br-language-selector {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.br-singup__validation-content {
  display: flex;
  flex-direction: row;
}

.br-signup {
  .br-forms-new-input.br-signup-form__fullname {
    margin-bottom: 16px;

    .br-signup-form__fullname {
      .ant-form-item-control {
        .ant-form-item-explain {
          margin-bottom: 0;
          margin-top: 2px;
        }
      }
    }
  }

  .br-signup__header__sllr-subtitle {
    color: @text-teal;
    margin: 0 6px;
    .display-md;
  }

  .br-signup__signin-link {
    margin-top: 32px;
    div {
      margin: auto;
      width: max-content;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.br-signup .br-signup-form__fullname .br-form-item-label__title {
  color: @text-gray-dark;
  .font({.body-medium()});
}

.br-signup .br-signup-form__business-name {
  margin-bottom: 14px;
}

.br-signup__form .ant-popover-content {
  width: max-content;
}

.br-login__track-order.br-button-component__with-suffix.button-md {
  span:last-child {
    margin-left: 8px;
    path {
      fill: none;
    }
  }
}

.br-registration-action-link .br-login__track-order {
  display: none;
}

.br-registration-content.ant-steps,
.br-signup-stepper.ant-steps {
  display: flex;
  align-items: center;
  .font({.caption()});
  width: fit-content;
  &.saudi-stepper {
    width: 215px;
    .ant-steps-item {
      padding: 0;
    }
  }
  .ant-steps-item {
    max-width: fit-content;
    padding-left: 0;
    .ant-steps-item-container {
      margin-left: 0;
      padding-bottom: 15px;
    }

    .font({.caption});
    padding: 0;

    &::before {
      display: none;
    }
    &::after {
      background: transparent
        url('../../assets/imgRevamp/StepperChevronRight.svg') no-repeat 50% 50%;
      transform: none;
      border: none;
      width: 16px;
      height: 16px;
      top: 47%;
    }
    .ant-steps-item-title {
      .font({.caption()});
      color: @text-gray;
      margin: 4px 12px 0 12px;
    }

    .ant-steps-item-icon {
      width: 28px;
      height: 20px;
      border: none;
      border-radius: 10px;
      background-color: @bg-selected;
      margin: 0 -4px 0 12px;
      .ant-steps-icon {
        line-height: 18px;
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: @icon-teal;
          }
        }
      }
    }
  }
  &.ant-steps-vertical {
    .ant-steps-item:last-child:after {
      display: none;
    }
  }
  .ant-steps-item-process,
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      display: none;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-title {
      color: @text-gray-dark;
    }
  }
}

.br-registration-content.ant-steps,
.br-signup-stepper.br-signup-stepper__rtl.ant-steps {
  .ant-steps-item {
    &::after {
      transform: scaleX(-1);
    }
  }
}

.br-signup__services-container {
  padding: 40px;
  max-width: 536px;
}

.br-signup__services-container {
  .ant-checkbox-group,
  .ant-space,
  .ant-checkbox-wrapper {
    width: 100%;
  }
  .br-services-checkbox-group {
    margin-top: 32px;
  }
  .ant-space-item {
    .ant-space-item-dev {
      border-radius: 4px;
      border: 1.5px solid @gray-200;
      padding: 15px;
    }

    .ant-space-item-checked {
      background-color: @bg-teal-light;
      border-color: @deep-teal;
    }

    .ant-space-item-disabled {
      border-color: @bg-disabled;
    }
  }
  .ant-checkbox-wrapper {
    border-radius: 4px;
    flex-direction: row-reverse;
    align-items: center;
    > span:last-child {
      width: 100%;
      display: flex;
      padding: 0;
    }
  }
}

.service {
  .service-description {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    > span:last-child {
      color: @text-gray;
    }
    .ant-tag {
      text-transform: uppercase;
      background-color: @gray-100;
      border: none;
      border-radius: 16px;
      margin-left: 8px;
    }
  }
}

.ant-space-item-disabled {
  min-height: 145px;
  .anticon svg {
    path {
      fill: @icon-subdued;
    }
  }
  .ant-checkbox-wrapper-disabled {
    span:last-child {
      color: @text-gray-light;
    }

    span:last-child.ant-radio-inner {
      color: @bg-gray;
    }
  }
  .interested-in-service-checkbox {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    .ant-checkbox-wrapper {
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      padding: 16px;
      border-radius: 4px;
      border: 1px solid @gray-200;
      background-color: @gray-50;
      span:last-child {
        color: @text-gray-dark;
      }
    }
  }
}

.br-signup__services-steps {
  color: @text-gray;
}

.br-signup__pricing_loading {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transform: scale(1.5);
  }
}

html[dir='rtl']
  .br-signup__pricing-container
  .br-signup__table-container
  .br-signup__info-row {
  & div.br-pricing-container__cashout-guide-modal__container {
    & svg {
      transform: scale(-1);
    }
  }
}

.br-signup__pricing-container {
  max-width: 1088px;
  margin-bottom: 50px;
  > div:first-child {
    padding: 40px;
  }

  > div.br-signup__pricing-container__pricing-section {
    padding: 40px 0;
    background-color: @bg-gray-light;
  }

  .br-signup__vat-section {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
  }

  .br-signup__vat-section__plan-selected {
    display: flex;
    flex-direction: column;
    padding: 16px;
    span:last-child {
      color: @text-gray;
    }
  }

  .br-signup__vat-toggle-container {
    display: flex;
    align-items: center;
    padding: 16px;
    .ant-switch {
      margin-left: 14px;
    }
  }
  .br-signup__pricing-subtitle {
    color: @text-gray;
  }

  .br-signup__table-container {
    margin-top: 32px;
    .br-signup__table-container__row {
      display: flex;
      justify-content: space-between;
    }
    .br-signup__title-column {
      width: 24%;
    }
    .br-signup__first-row-plans {
      width: 74%;
      gap: 8px;
      display: flex;
      justify-content: space-between;
    }
    .br-signup__first-row-title-column {
      display: flex;
      flex-direction: column;
      div {
        height: 45%;
        padding: 20px 16px;
        color: @text-gray;
        &:first-child {
          box-shadow: @border-bottom;
        }
      }
    }
    .br-signup__plan-card {
      box-shadow: @border-all-sides;
      border-radius: 8px;
      width: 100%;
      position: relative;
      div.br-signup__plan-card__description {
        height: 45%;
        padding: 24px;
        box-shadow: @border-bottom;
      }
      div.br-signup__plan-card__price {
        height: 55%;
        padding: 16px;
      }
      .header {
        color: @text-gray-light;
        margin-bottom: 8px;
      }
      .title {
        margin-bottom: 16px;
      }
      .description {
        margin-bottom: 0;
      }
      .br-signup__title,
      .description {
        color: @text-gray-dark;
      }
      .vat {
        font-style: italic;
        color: @text-gray-light;
      }
      button {
        width: 100%;
      }
      .tag {
        padding: 0 6px;
        position: absolute;
        top: -8px;
        left: calc(50% - 80px);
        color: @text-red;
        background-color: @bg-red-light;
        text-transform: uppercase;
        text-align: center;
        border: none;
        border-radius: 16px;
        width: fit-content;
        height: 16px;
      }
    }
    .br-signup__plan-card.active {
      box-shadow: @shadow-lg;
      div.br-signup__plan-card__description {
        background-color: @teal-100;
        .header {
          color: @text-teal;
        }
      }
      div.br-signup__plan-card__price {
        border-bottom: 3px solid @deep-teal;
        border-radius: 8px;
      }
    }
    .br-signup__more-than-row-container {
      display: flex;
      flex-direction: column;
      align-items: end;
      text-align: center;
      margin-top: 16px;
      div.br-signup__more-than-row {
        display: flex;
        flex-direction: column;
        width: 74%;
        background-color: @bg-hovered;
        border-radius: 8px;
        padding: 16px;
        span:first-child {
          color: @text-gray-dark;
        }
        span:last-child {
          color: @text-gray;
        }
      }
    }

    .br-signup__info-row {
      margin-top: 16px;

      .br-signup__title {
        padding: 20px 16px;
        display: block;
      }
      & .br-pricing-container__cashout-guide-modal__container {
        box-shadow: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200,
          inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
        border-radius: 8px;
        padding: 16px 24px;
        background: white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & button.ant-btn.br-button-component {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
      .br-signup__table-container__row {
        .br-signup__info-row-plans {
          width: 74%;
          display: flex;
        }
        .br-signup__info-row-plans.with-cashout-guide {
          display: flex;
          flex-direction: column;

          & > div:first-child {
            display: flex;
            width: 100%;
          }
          & .br-pricing-container__cashout-guide-modal__container {
            box-shadow: inset 1px 0px 0px @gray-200,
              inset -1px 0px 0px @gray-200, inset 0px -1px 0px @gray-200,
              inset 0px 1px 0px @gray-200;
            border-radius: 0px 0px 8px 8px;
            padding: 16px 24px;
            background: white;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & button.ant-btn.br-button-component {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }
        }

        .br-signup__info-row-title-column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          span {
            padding: 20px 16px;
            color: @text-gray;
            .font({.body()});
          }
        }
        .br-signup__info-row-title-column.with-cashout-guide {
          justify-content: unset;
        }
        .br-signup__info-row-plan-column {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          box-shadow: @border-top;
          > span {
            padding: 20px 24px;
            .font({.body-medium()});
            color: @text-teal;
            svg path {
              fill: @icon-teal;
            }
            &.br-signup__not-applied {
              .font({.body()});
              color: @text-gray-light;
            }
            &.br-signup__percentage span {
              .font({.body()});
              color: @text-gray-dark;
              border-bottom: 1px dashed @gray-300;
            }
            &.br-signup__frequency {
              .font({.body-medium()});
              color: @text-gray-dark;
              span {
                .font({.body()});
                color: @text-gray;
              }
            }
          }
        }
        .br-signup__info-row-plan-column.active {
          box-shadow: @shadow-lg;
        }
      }
    }
  }

  .br-signup__pricing-table {
    padding: 0 40px;
    .ant-table {
      border-radius: 8px;
      .ant-table-tbody > tr:last-of-type > td {
        border: none;
      }
    }

    .ant-table .ant-table-container::before,
    .ant-table .ant-table-container::after {
      display: none;
    }

    .ant-table-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 1px solid @gray-200;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 0;
      max-height: 60px;
      .br-signup__pricing-city-select {
        display: flex;
        align-items: center;
        padding: 16px;
        border-radius: 8px 0 0 0;
        background-color: @bg-hovered;
        .br-signup__city-from-span {
          text-transform: uppercase;
          color: @text-gray;
        }
        .ant-select {
          margin-left: 16px;
          .ant-select-selector {
            border-radius: 24px;
            height: 28px;
            min-height: unset;
          }
          .br-signup-pricing__select-options {
            display: flex;
            flex-direction: column;
            padding: 8px;
            .br-area__title-span {
              color: @text-gray;
            }
            .br-area__first-span {
              color: @text-gray-dark;
            }
            .br-area__second-span {
              color: @text-gray;
            }
          }
          .ant-select-selection-item {
            justify-content: center;
            .br-area__second-span {
              display: none;
            }
          }
          .ant-select-item-option-selected {
            background-color: @bg-selected;
            font: unset;
          }
          .ant-select-dropdown
            .ant-select-item-option-content
            .br-signup-pricing__select-options
            .br-area__title-span {
            display: none;
          }
        }
      }
      .br-signup__dropoff-city-span {
        display: flex;
        align-items: center;
        color: @text-gray;
        padding-left: 16px;
        text-transform: uppercase;
      }
      .br-signup__pricing-dropoff-cities {
        display: flex;
        align-items: center;
        overflow-x: hidden;
        .br-signup__pricing-dropoff__scroll-button {
          z-index: 1;
          height: 100%;
          padding: 0 24px;
          justify-content: space-around;
          border: none;
          border-radius: 8px;
          svg {
            transform: scale(1.2);
          }
        }

        .br-signup__dropoff-cities__buttons-container {
          display: flex;
          overflow-x: scroll;
          scroll-behavior: smooth;
          .br-signup__dropoff-cities__button {
            margin: 0 4px;
            border-radius: 24px;
            border-color: @gray-200;
            box-shadow: none;
            max-height: 28px;
            span {
              color: @text-gray-dark;
            }
          }
          .br-signup__active-city-to-button {
            background-color: @bg-gray-dark;
            border: none;
            span {
              color: @text-white;
            }
          }
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
    .ant-table-content {
      border-radius: 0 0 8px 8px;
      .ant-table-thead th {
        color: @text-gray;
        .font({.subheading()});
        text-transform: uppercase;
        &::before {
          display: none;
        }
      }

      tr[data-row-key='0'],
      tr[data-row-key='0'] .ant-table-cell-row-hover {
        background-color: @bg-teal-light;
        .ant-table-cell {
          height: 52px;
          padding: 0 16px;
        }
      }

      .ant-table-cell {
        height: 56px;
        padding: 0 12px;
      }
    }
    .pricing-extra-info-container {
      display: flex;
      flex-direction: column;
      .pricing-extra-info__size-tooltip {
        margin-left: 4px;
      }
      .pricing-extra-info__size-description {
        color: @text-gray;
      }
    }
  }

  .br-signup__pricing-other-revenue-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @bg-gray-light;
    margin-top: 24px;
    box-shadow: @border-all-sides;
    border-radius: 8px;
    margin: 24px 40px 0 40px;
    .br-signup__other-revenue {
      flex-basis: 100%;
      padding: 16px;
    }
    .br-signup__other-revenue:not(:last-child) {
      box-shadow: @border-right;
    }
    .br-signup__other-revenue:is(:only-child) {
      width: 100%;
    }
    .br-signup__other-revenue-section__header {
      color: @text-gray-dark;
    }
    .br-signup__other-revenue-section__description {
      color: @text-gray;
    }
  }

  .br-signup__pricing-other-revenue-section:empty {
    display: none;
  }

  .br-signup-pricing__scroll-header {
    background-color: red;
    display: none;
    justify-content: center;
    height: 70px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 3;
    background-color: @white;
    box-shadow: @shadow-sm;
    .br-signup__scroll-header-business,
    .br-signup__scroll-header-basic {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      width: 50%;
      height: 100%;
      margin: 0;
      padding: 0 16px;
      > span:last-child {
        width: 90%;
      }
      .subheading {
        color: @text-gray;
      }
    }
    .br-signup__scroll-header-business {
      box-shadow: @border-right;
    }
    .active {
      border-bottom: 3px solid @deep-teal;
      .subheading {
        color: @text-teal;
      }
    }
  }

  .br-signup__per-order {
    margin-left: 4px;
    color: @text-gray;
  }
}

html[dir='rtl'] .br-signup__pricing-dropoff__scroll-button {
  > span {
    transform: scale(-1);
  }
}

html[dir='rtl']
  .br-signup__pricing-container
  .br-signup__table-container
  .br-signup__plan-card
  .tag {
  left: calc(50% - 32px);
}

.br-signup-services__footer {
  font-size: 0;
  line-height: 0;
  .ant-progress {
    font-size: 0;
  }
  .ant-progress-inner {
    background-color: @bg-gray;
  }
  .ant-progress-bg {
    height: 2px !important;
    background-color: @deep-teal;
  }
  .br-signup__footer {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding: 16px 20px;
    background: @bg-gray-light;
    box-shadow: @border-top;
    border-radius: 0 0 4px 4px;
    .ant-btn:first-child {
      margin-right: 12px;
    }
  }
  .br-signup__footer.br-signup-services__footer {
    justify-content: flex-end;
  }
  .br-signup__footer .ant-btn:first-child {
    margin-right: 0;
  }
}

html[dir='rtl']
  .br-signup-services__footer
  .br-signup__footer
  .br-button-component__with-prefix
  svg {
  transform: scale(-1);
}

.br-signup-pricing__fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1088px;
  z-index: 2;
}

.br-signup-pricing__fixed-footer.br-signup-pricing__fixed-ksa-footer {
  max-width: 975px;
}

.br-signup .ant-col.ant-form-item-label {
  margin-bottom: 4px;
}

.br-signup .br-forms-new-input > div {
  margin-bottom: 0;
}

.br-signup .br-content-header {
  margin-bottom: 24px;
}

.br-sign-up__plan-card__price-container {
  min-height: 90px;
  margin-bottom: 16px;
}

.br-signup__content-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

html[dir='rtl'] .br-signup-services__footer {
  .ant-btn {
    svg {
      transform: scale(-1);
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-registration-action-link {
    display: flex;
    justify-content: center;
    margin: 0;
    .br-login__track-order {
      display: flex;
    }
  }

  .br-signup__pricing-container {
    > div:first-child {
      padding: 20px;
    }
    .br-signup__table-container {
      .br-signup__title-column {
        width: 50%;
      }
      .br-signup__first-row-plans {
        width: 100%;
        flex-direction: column;
      }
      .br-signup__first-row-title-column {
        display: none;
      }
      .br-signup__plan-card {
        width: 100%;
        margin: 8px 0;
      }
      .br-signup__more-than-row-container {
        div.br-signup__more-than-row {
          width: 100%;
        }
      }
      .br-signup__info-row {
        .br-signup__table-container__row {
          .br-signup__info-row-plans {
            width: 40%;
          }
          .br-signup__info-row-plan-column {
            width: 100%;
            box-shadow: none;
          }
          .br-signup__info-row-plan-column.active {
            box-shadow: none;
          }
          .br-signup__info-row-plan-column:not(.active) {
            display: none;
          }
        }
      }
    }
    .br-signup__pricing-other-revenue-section {
      flex-direction: column;
      margin: 24px 20px 0 20px;
      .br-signup__other-revenue {
        width: 100%;
        padding: 16px;
      }
      .br-signup__other-revenue:not(:last-child) {
        box-shadow: @border-bottom;
      }
    }

    .br-signup__vat-section {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      .br-signup__vat-section__plan-selected {
        padding: 16px 0;
        box-shadow: @border-bottom;
      }
      .br-signup__vat-toggle-container {
        padding: 16px 0;
        justify-content: space-between;
      }
    }

    .br-signup__pricing-table {
      padding: 0 20px;
      .ant-table-title {
        .br-signup__pricing-city-select {
          width: 50%;
          justify-content: center;
          padding: 16px 0;
          &:first-child {
            box-shadow: @border-right;
          }
          .ant-select {
            margin-left: 0;
            width: 130px;
            .ant-select-selector {
              border-radius: 8px;
              height: 55px;
            }
            .ant-select-selection-item {
              justify-content: flex-start;
            }
            .br-signup-pricing__select-options {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .br-signup-pricing__scroll-header {
    .ant-radio-wrapper > span:last-child {
      padding: 0;
    }
  }

  .br-signup .ant-col.ant-form-item-label {
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

@media only screen and (max-width: @md) {
  .br-registration-content,
  .br-signup-stepper {
    .ant-steps {
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      margin: 0 20px;
    }
  }

  .br-signup__pricing-container,
  .br-signup-pricing__fixed-footer {
    width: calc(100vw - 16px);
  }

  .br-signup__pricing-container
    .br-signup__table-container
    .br-signup__info-row {
    .br-signup__title {
      padding: 12px 0 4px 0;
    }

    .br-signup__info-row-title-column.br-signup__title-column,
    .br-signup__info-row-plans .br-signup__info-row-plan-column {
      justify-content: flex-start;
      span {
        max-height: 44px;
        padding: 12px 0;
      }
    }
  }

  .br-signup__pricing-container
    > div.br-signup__pricing-container__pricing-section {
    padding: 0 0 40px 0;
  }

  .br-signup {
    .br-signup__signin-link {
      margin-top: 24px;
    }
  }
}

.br-pricing-plan-card__next-day-fees-text {
  margin-bottom: 0px;
}
