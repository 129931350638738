@import 'styles/variables';

.br-signup-form__container {
  display: flex;
  justify-content: center;
  flex: 60%;
  padding: 40px 0;

  .br-signup__form__terms {
    margin-top: 16px;
  }
}

.br-signup-form__inner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 536px;
  padding: 0 24px;
}

.br-signup-form__row {
  display: flex;
  align-items: center;
  gap: 16px;

  & > div {
    flex: 1;
  }
}

.br-signup-form__submit-btn {
  width: 100%;
  margin-top: 32px;
}

.br-signup-form__login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

@media only screen and (max-width: @sm) {
  .br-signup-form__row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    & > div {
      width: 100%;
    }
  }
}
