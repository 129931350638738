@import 'styles/variables.less';

.br-google-map.br-google-map-modal_location {
  margin: 1px 20px 16px;
  height: 356px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div:nth-child(2) > div {
    border-radius: 4px;
  }
  .gmnoprint.gm-bundled-control .gmnoprint {
    div {
      border-radius: 4px !important;
      max-width: 36px;
      button {
        max-width: 100%;
        background-position: center !important;
        background-repeat: no-repeat !important;
        img {
          max-width: 0;
        }
      }
      button:first-child {
        background-image: url('../../assets/bosta-icons/Plus.svg') !important;
      }
      button:last-child {
        background-image: url('../../assets/bosta-icons/Mins.svg') !important;
      }
      div:nth-child(2) {
        display: none;
      }
    }
  }
  .gm-style-cc {
    display: none;
  }
}

.br-google-map.br-google-map-modal_location.br-marker-error {
  .gmnoprint.gm-bundled-control .gmnoprint {
    top: -61px !important;
  }
}

.br-google-map .gm-style .gm-style-iw-c,
.br-google-map .gm-style .gm-style-iw-t::after {
  top: -15px;
}

.br-google-map__info-window {
  padding: 0;
  margin: 0;
}

.br-google-map .gm-style-mtc,
.br-google-map .gm-svpc,
.br-google-map .gm-control-active {
  display: none;
}

.pac-container {
  z-index: 1073 !important;
}

.br-google-map__marker-with-search {
  position: absolute;
  left: 45%;
  top: 40%;
  z-index: 1;
}

.br-google-map__marker {
  position: relative;
  left: 45%;
  top: 40%;
  z-index: 1;
  width: 20px;
  height: 20px;
}

.br-google-map__marker__note {
  position: absolute;
  width: 50%;
  top: 50%;
  left: 25%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-google-map__marker__error__message {
  position: absolute;
  width: 470px;
  border-radius: 4px;
  padding: 16px;
  left: 32px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.br-google-map__marker__error__undetermined {
  height: 81px;
  background-color: @yellow-50;
  border: 1px solid @yellow-200;
  top: 266px;
}

.br-google-map__marker__error__undetermined a {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

.br-google-map__marker__error__failed {
  height: 52px;
  background-color: @red-50;
  border: 1px solid @red-200;
  top: 296px;
}

html[dir='rtl'] .br-google-map__marker__note {
  right: 25%;
}

.br-google-map__marker__note .anticon-caret-up {
  height: 10px;
}

.br-google-map__marker__note__text {
  width: 100%;
  background: @bg-gray-dark;
  padding: 12px;
  margin: 0;
}
.br-google-map__marker__note__title {
  color: @white;
  border-radius: 8px 8px 0 0;
  box-shadow: @border-bottom;
}

.br-google-map__marker__note__description {
  color: @gray-300;
  border-radius: 0 0 8px 8px;
}

.br-google-map__marker img,
.br-google-map__marker-with-search img {
  width: 40px;
  height: 40px;
}

.br-google-map-modal .gm-style-mtc,
.br-google-map-modal .gm-svpc,
.br-google-map-modal .gmnoprint span {
  display: none;
}

.br-google-map-modal .gm-style-mtc,
.br-google-map-modal .gm-svpc,
.br-google-map-modal .gmnoprint span {
  display: none;
}

.br-google-map-modal_location .gm-style {
  overflow: hidden;
  border-radius: 4px;
}

.br-google-map-modal_location .br-google-map-container {
  overflow: unset !important;
  height: 100%;
}

.br-google-map-card__location .br-google-map-container {
  height: 100%;
  width: 100%;
}

.br-google-map-modal_location .br-google-map-container div:first-child {
  height: 100%;
}

.br-google-map .gm-style > div > div a {
  display: none !important;
}
