@import 'styles/variables';

.br-confirm-reset-container .br-registration-form-card {
  width: 536px;
}

.br-confirm-reset-parent {
  display: flex;
  flex-direction: column;
}

.br-confirm-reset-parent__title {
  text-transform: uppercase;
  color: @text-gray;
  margin-bottom: 4px;
}

.br-confirm-reset-parent__user-email {
  margin-top: 4px;
  color: @text-gray;
}

.br-confirm-reset-parent__check-icon {
  margin-bottom: 32px;
}

@media only screen and (max-width: @xs) {
  .br-confirm-reset-parent__check-icon {
    margin-bottom: 28px;
  }
  .br-confirm-reset-container .br-registration-form-card {
    width: calc(100% - 16px);
  }
}
