@import '~antd/dist/antd.less';
@import './variables.less';
@import './antd-overrides.less';
@import './fonts.less';
@import './new-fonts.less';
@import './fonts-classes';

#root {
  height: 100%;
  overflow-x: hidden;
}

*:focus {
  outline: none;
}

.app,
body {
  background-color: white !important;
  color: @text-gray-dark;
}
html {
  position: fixed;
  left: 0;
  right: 0;
}

html,
body {
  overflow: hidden;
  height: 100%;
  .font({.body()});
}

.app-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: @gradient;
  .br-container {
    background-color: @white;
  }
}

::-webkit-scrollbar-thumb {
  background-color: @gray-200;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 16px;
  background: @gray-50;
  border-left: 1px solid @gray-200;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(@black, 0);
  background-clip: padding-box;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: @text-gray-light;
  .font({
    .body()
  });
}

.ant-message {
  z-index: 2022;
}

.bold {
  font-weight: bold;
  font-family: @font-500;
}

.underline {
  text-decoration: underline;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.position-relative {
  position: relative;
}

.display-inline {
  display: inline;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

.flex-equal-children > * {
  flex: 1;
}

.flex-warp {
  flex-wrap: wrap;
}

.flex-dir-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-flex-start {
  justify-content: flex-start;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap;
}

.br-uncovered-area {
  cursor: not-allowed;
}

.br-ellipsis-cell-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

//icons classes
.ant-icon-xs {
  font-size: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
}

.ant-icon-sm {
  font-size: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.ant-icon-md {
  font-size: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-icon-lg {
  font-size: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.ant-icon-xl {
  font-size: 32px;
  svg {
    width: 32px;
    height: 32px;
  }
}

// Shadow
.shadow-md {
  box-shadow: 0px 2px 4px rgba(@gray-800, 0.05),
    0px 4px 16px rgba(@gray-800, 0.1);
}

.shadow-xs {
  box-shadow: 0px 1px 2px 0px rgba(@gray-800, 0.1);
}

.shadow-pages-bg {
  box-shadow: 0px -2px 8px 2px rgba(@deep-teal, 0.16);
}

.border-bottom {
  box-shadow: inset 0px -1px 0px @gray-200;
}

// forms
.br-form-row,
.br-form-with-sub-title,
.br-form-without-label,
.br-form-text-area-label {
  display: flex;
}

.br-form-with-sub-title.ant-row.ant-form-item {
  min-height: 95px;
}

.br-form-with-sub-title.ant-form-item > .ant-form-item-label {
  height: 40px;
}

.br-form-without-label.ant-row.ant-form-item {
  min-height: 50px;
}

.br-form-text-area-label.ant-row.ant-form-item {
  min-height: 110px;
  margin-bottom: 12px;
}

.br-form-item-words-count.ant-form-item > .ant-form-item-label > label,
.br-form-item-words-count.ant-form-item > .ant-form-item-label {
  width: 100%;
  justify-content: space-between;
  height: 20px;
}

.br-form-item-words-count.ant-form-item .br-form-words-count-label {
  color: @text-color;
  font-size: 12px;
  font-family: @font-400;
  font-weight: normal;
}

.br-form-item-label__title {
  color: @text-gray-dark;
  display: flex;
  align-items: center;
}

.br-form-item-label__sub-title {
  color: @text-gray-light;
}

html[dir='rtl'] {
  .ant-form-item-label {
    line-height: 0;
  }

  .br-form-item-label__sub-title {
    line-height: 7px;
  }
}

.br-form-row__half-width {
  max-width: calc(50% - 11px);
}

.ant-form-item {
  min-width: 0;
}

.br-form-row__city-area .ant-row.ant-form-item {
  flex: 2 1 auto;
}

.br-form-row__city-area .ant-row.ant-form-item:first-child {
  flex: 1;
}

.br-form-row__city-area .ant-row.ant-form-item:nth-child(2) {
  flex: 2;
}

@media only screen and (max-width: 600px) {
  .br-form-row__half-width {
    max-width: unset;
  }
}

html[dir='ltr'] .br-form-row > *:not(:last-child),
html[dir='ltr'] .br-form-with-sub-title > *:not(:last-child) {
  margin-right: 14px;
}

html[dir='rtl'] .br-form-row > *:not(:last-child),
html[dir='rtl'] .br-form-with-sub-title > *:not(:last-child) {
  margin-left: 14px;
}

.br-form-row > * {
  flex: 1;
}

@media only screen and (max-width: @xs) {
  .br-form-row,
  .br-form-with-sub-title {
    flex-direction: column;
  }

  .br-form-row .ant-row.ant-form-item:not(:last-child) {
    margin-right: 0;
  }
  div.app-body div.br-parent-container {
    padding: 2px 10px;
  }
}

.ant-form-item .ant-form-item-label .br-form-optional-label {
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: @gray-400;
  .font({.caption()});
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 3px;
  height: fit-content;
}

.ant-form-item .ant-form-item-label .br-form-optional-label-new {
  color: @text-color-30;
  font-size: 12px;
  font-family: @font-500;
  vertical-align: middle;
  margin-left: 10px;
  background: @background-color-5;
  padding: 5px;
}

body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

button.br-add-button.ant-btn {
  color: @text-color-29;
  font-family: @font-600;
  font-size: 13px;
  font-weight: 600;
  height: 24px;
  padding: 0;
  border: 0;
  box-shadow: none;
}

button.br-add-button.ant-btn span {
  color: @text-color-29;
  font-family: @font-600;
  font-size: 13px;
  font-weight: 600;
}

.br-add-button .anticon {
  font-size: 12px;
  height: 14px;
}

#fc_frame,
#fc_frame.fc-widget-small {
  bottom: 60px !important;
}

.fc-mobile-view,
.fc-widget-small {
  z-index: 2 !important;
}

html[dir='rtl'] #fc_frame {
  right: calc(100% - 84px) !important;
}

#fc_frame.fc-widget-small.h-open-container,
#fc_frame.h-open-container {
  left: calc(100% - 400px) !important;
  transform: scaleX(1) !important;
}

html[dir='rtl'] #fc_frame.fc-widget-small.h-open-container,
html[dir='rtl'] #fc_frame.h-open-container {
  right: calc(100% - 400px) !important;
}

.br-order-description__actionable-success {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .br-form-with-sub-title.ant-form-item > .ant-form-item-label {
    height: 60px;
  }

  .br-form-with-sub-title.ant-row.ant-form-item {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 600px) {
  #fc_frame,
  #fc_frame.fc-widget-small {
    // bottom: 10px !important;
    right: 5px !important;
  }
}

@media only screen and (max-width: @xs) {
  #fc_frame,
  #fc_frame.fc-widget-small {
    bottom: 63px !important;
  }
}

@-moz-document url-prefix() {
  table input[type='checkbox'] {
    visibility: visible;
  }
}

@media only screen and (max-width: 425px) {
  .ant-form-item > .ant-form-item-label {
    height: auto;
  }
}

@media only screen and (max-width: 320px) {
  .ant-picker-dropdown {
    left: -21px !important;
  }
}

@-moz-document url-prefix() {
  table input[type='checkbox'] {
    visibility: visible;
  }
}

.br-custom-error-visible {
  margin-bottom: unset;
}

.br-custom-error-visible
  div.ant-form-item-control
  div.ant-form-item-control-input-content
  input.ant-input {
  border: 1px solid @border-color-15;
}

.br-custom-error {
  color: @text-color-27;
}

.br-table-order-id {
  letter-spacing: -0.04em;
}

._hj-1O2Rr__MinimizedWidgetMiddle__container._hj-1QnQT__MinimizedWidgetMiddle__right {
  top: 500px !important;
}

.br-delete-action .ant-dropdown-menu-title-content,
.br-delete-action .ant-dropdown-menu-title-content span {
  color: @red-700;
}

.ant-dropdown-menu-item-disabled.br-delete-action
  .ant-dropdown-menu-title-content {
  color: @red-300;
  span {
    color: @red-300;
  }
}

.app-loading-wrapper {
  height: 100vh;
  .ant-spin-dot {
    width: unset;
    height: unset;
  }
}

html[dir='rtl'] #_hj_feedback_container > div {
  left: 0;
  right: unset;
}

.ant-tag.br-state-teal-group {
  background-color: @teal-100;
  color: @teal-700;
}

.ant-tag.br-state-blue-group {
  background-color: @blue-100;
  color: @text-blue-dark;
}

.ant-tag.br-state-green-group {
  background-color: @green-100;
  color: @text-green-dark;
  & svg {
    width: 12px;
    height: 12px;
    path {
      fill: @text-green-dark;
    }
  }
}

.ant-tag.br-state-gray-group {
  background-color: @gray-100;
  color: @text-gray-dark;
  & svg {
    width: 12px;
    height: 12px;
    path {
      fill: @text-gray-dark;
    }
  }
}

.ant-tag.br-state-red-group {
  background-color: @red-100;
  color: @text-red-dark;
}

.ant-tag.br-state-yellow-group {
  background-color: @yellow-100;
  color: @yellow-900;
  & svg {
    width: 12px;
    height: 12px;
    path {
      fill: @yellow-900;
    }
  }
}

.ant-notification .ant-notification-notice.br-separation__notification {
  border-radius: 8px;
  background-color: @yellow-600;
  padding: 12px;
  top: 96px;

  .ant-notification-notice-message {
    margin-bottom: 0;
    color: @white;
    display: flex;
    gap: 8px;
    padding-right: 20px;

    path {
      fill: @yellow-900;
      stroke: @white;
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  .ant-notification-notice-btn {
    display: flex;
    gap: 8px;
    width: 100%;
    margin-top: 12px;

    .ant-btn {
      gap: 4px;
      width: 100%;

      &.link-ocean .br-button-component__label {
        color: @white;
      }
    }

    svg path {
      fill: transparent;
      stroke: @icon-default;
    }
  }

  .anticon-close {
    path {
      fill: @white;
    }
  }
}
