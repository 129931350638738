@import 'styles/variables';

.br-location-form {
  display: flex;
  flex-direction: column;
  flex: 1 2 auto;
  padding: 0 20px 24px;
}

.ant-row.ant-form-item.br-forms-new-input.br-form-city-area {
  margin-bottom: 4px;
}

.br-form-row.br-location-form__location-name {
  margin-bottom: 4px;
  > .ant-row.ant-form-item
    > .ant-col.ant-form-item-label
    > .ant-form-item-required {
    .font({.body-medium});
  }
  .br-address-details {
    margin-bottom: 20px;
  }
}

.br-contact-form {
  padding: 16px 20px;
  width: 488px;
  .br-form-row:not(:first-child) {
    margin: 16px 0 0;
  }

  .ant-row.ant-form-item {
    min-height: 1px;
    .ant-form-item-control-input-content input {
      height: 36px;
    }
  }

  .ant-btn.link-color {
    max-height: 20px;
    margin: 8px 0 2px;
  }

  .br-forms-new-input {
    min-height: unset;
  }
  .br-form-item-label__title {
    .font({.body-medium()});
    color: @text-gray-dark;
  }
  .br-contact-form__set-default {
    margin-top: 20px;
  }
}

.br-location-contact-form-header {
  margin: 0;
  padding: 0 20px;
}

.br-location-contact-form-header__title {
  color: @text-gray-dark;
}

.br-location-contact-form-header__subtitle {
  color: @text-gray;
}

.br-location-form > div:first-child {
  flex: 1;
}

.br-location-contact-form .ant-form-item > .ant-form-item-label {
  margin-bottom: 4px;
}

.br-location-form .br-google-map-card__location {
  height: 235px;
}

.br-location-contact-modal.ant-modal {
  max-width: 536px;
  border-radius: 8px;
  .br-content-header.br-location-contact-form-header {
    padding: 16px 20px;
    box-shadow: @border-bottom;
  }
  .ant-modal-content .ant-modal-body {
    padding: 0;
    .ant-form-horizontal > div {
      max-height: 60vh;
      overflow: auto;
    }
  }
}

.br-location-contact-form .ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  margin-bottom: 0;
  .ant-input {
    padding: 7px 11px;
  }
  .ant-form-item-control-input {
    min-height: 36px;
  }
}

.br-location-contact-form .ant-form-item-label {
  text-align: start;
  margin-bottom: 0;
}

.br-location-contact-form__header {
  display: flex;
  flex-direction: column;
}

.br-location-contact-form .ant-row.ant-form-item:not(:last-child) {
  margin-right: 6px;
}

.br-location-contact-form .ant-input {
  border-radius: 4px;
}

.br-location-contact-modal.ant-modal {
  .br-content-header.br-location-contact-form-header-with-back {
    padding: 16px 5px;
    button.br-button-component__with-icon-only {
      padding: 15px;
    }
  }
}

.br-location-contact-form__footer__with-back {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.br-location-contact-form__footer {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.br-location-contact-form__footer__right-side-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 20px;
  background: @bg-gray-light;
  box-shadow: @border-top;
  border-radius: 0 0 4px 4px;
  .ant-btn:first-child {
    margin-right: 12px;
  }
}

@media only screen and (max-width: @md) {
  .br-location-form {
    display: flex;
  }
  .br-location-form .br-google-map-card {
    margin-left: 0;
  }
  .br-contact-form .br-form-row > *:not(:last-child) {
    margin-right: unset;
    margin-bottom: 6px;
  }

  .br-contact-form .br-form-row > :last-child .ant-row.ant-form-item {
    margin-bottom: unset;
  }
}

@media only screen and (max-width: @xs) {
  .br-location-contact-modal.ant-modal {
    min-width: 100%;
  }
  .br-contact-form {
    width: unset;
  }
}
