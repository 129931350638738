@import 'styles/variables';

.br-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.br-empty-state__image {
  // height: 31px;
  // width: 59px;
  margin-bottom: 14px;
}

.br-empty-state p {
  margin: 0;
}

.br-empty-state__title {
  color: @text-gray-dark;
  margin-bottom: 4px;
}

.br-empty-state__sub-title {
  color: @text-gray;
}

.br-empty-state__actions {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .ant-btn {
    padding: 8px 16px;
  }
}

.br-empty-state__extra-content {
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 24px;
}

@media only screen and (max-width: @lg) {
  .br-empty-state__extra-content {
    flex-direction: column;
  }
}

@media only screen and (max-width: 350px) {
  .br-empty-state__actions {
    flex-direction: column;
  }
}
