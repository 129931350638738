@import 'styles/variables';

.br-signup__terms-modal {
  overflow: auto;
  max-height: 60vh;
  max-width: 864px;
  margin: auto;
  box-sizing: border-box;
  border: 1px solid @gray-200;
  border-radius: 4px;
  background-color: @background-color;
  padding: 10px;
  white-space: pre-wrap;
  position: relative;
}

html[dir='rtl'] .br-signup__terms-modal::-webkit-scrollbar-track {
  border: none;
  border-left: 1px solid @gray-200;
}

html[dir='ltr'] .br-signup__terms-modal::-webkit-scrollbar-track {
  border: none;
  border-right: 1px solid @gray-200;
}

.br-signup__terms-modal::-webkit-scrollbar {
  border-radius: 4px;
  border: none;
}

html[dir='rtl'] .br-signup__terms-modal {
  text-align: left;
  direction: ltr;
}

html[dir='ltr'] .br-signup__terms-modal.egypt {
  text-align: right;
  direction: rtl;
  .font({.body()});
}

.br-terms-modal {
  .ant-modal-content .ant-modal-body {
    padding: 0;
    > .ant-btn {
      z-index: 1;
      border-radius: 30px;
      height: 25px;
      width: 140px;
      text-transform: uppercase;
      position: absolute;
      bottom: 98px;
      right: calc(50% - 70px);
      background-color: @gray-900;
      border-color: transparent;
      cursor: default;
      &:hover {
        background-color: @gray-900;
        border-color: transparent;
      }
    }
    .br-sign-up__scroll-down-icon svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.br-terms-modal .br-terms-modal-header {
  padding: 16px 24px;
  box-shadow: @border-bottom;
}

.br-terms-modal .ant-modal-header {
  border: 0;
  padding: 24px 24px 23px 24px;
}

.br-terms-modal .br-modal-sub-heading {
  font-size: 14px;
  text-align: center;
  margin-top: 7px;
}

.br-terms-modal .ant-modal {
  min-width: 904px;
}

.br-terms-modal .ant-modal-body {
  padding-top: unset;
}

html[dir='ltr'] .br-signup__terms-modal.egypt {
  p,
  span:first-child {
    font-family: 'Cairo/Medium';
  }
}

@media only screen and (max-width: 992px) {
  .br-signup__terms-modal {
    max-height: 460px;
    max-width: 723px;
  }

  .br-terms-modal .ant-modal {
    min-width: 80vw;
  }

  .br-terms-content__price-note-city {
    display: flex;
    flex-direction: column-reverse;
  }

  .br-terms-content__notes-items {
    margin-left: 0px;
    margin-bottom: 10px;
    padding-left: 22px;
  }
}

.br-signup__terms-modal p span:first-child {
  text-align: center;
  .font({.heading()});
}

.br-signup__terms-modal p span {
  font-weight: bold;
  display: block;
}

.br-terms-modal .ant-modal-footer {
  margin-top: 20px;
}
