.br-page-404__left-text {
  font-size: 50px;
  margin-right: 10px;
}

.br-page-404__right-text {
  color: #73818f;
}

.br-page-404__left-text-warning {
  padding-top: 15px;
}
