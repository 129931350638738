@import 'styles/variables';

.br-activation-step__subtitle {
  color: @text-gray;
  margin-top: 4px;
}

.br-activation-step__actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 16px;
}

.br-activation-steps {
  &.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-top: 2px;
    margin-right: 18px;
  }

  &.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 36px;
  }

  &.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    width: 2px;
    background-color: @teal-300;
    margin-left: 17px;
    border-radius: 0;
  }

  &.ant-steps-vertical.ant-steps-dot
    .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    top: 2.5px;
    left: -8px;
  }

  .ant-steps-item-process.ant-steps-item-active
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    padding-bottom: 24px;
    width: 100%;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: @text-gray;
    text-decoration: line-through;
  }
}

.br-activation-steps-content__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

html[dir='rtl'] .br-activation-steps-content__container {
  > svg {
    transform: scale(-1);
  }
}

.br-activation-steps__setup-guide {
  .br-activation-step__actions {
    gap: 8px;
  }

  .br-activation-step__actions > .ant-btn:last-child {
    padding-left: 12px;
  }
}

@media only screen and (max-width: @xs) {
  .br-activation-step__actions {
    gap: 8px;
  }

  .br-activation-steps-content__container > svg {
    display: none;
  }
}
