@import 'styles/variables';

.br-notify__message,
.ant-message-custom-content {
  display: flex;
  align-items: center;
}

.br-notify__message__subtitle {
  margin-left: 4px;
}

.br-notify-message__hidden {
  display: none;
}

.br-notify__message__content {
  display: flex;
  margin-left: 5px;
  color: @text-gray-dark;
  .font({.body-medium()});
}

.error .ant-message-notice-content {
  padding: 10px 14px;
}

.ant-message .ant-message-notice-content {
  border-radius: 8px;
  .font({.body-medium()});
}

.ant-message-notice .ant-message-notice-content {
  box-shadow: @shadow-md;
  & .anticon:first-child {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-message-notice.success .ant-message-notice-content {
  background: @green-50;
  color: @teal-900;
  & .anticon-check-circle {
    background: @green-200;
    border: 4px solid @green-100;
    color: @green-700;
  }
}

.ant-message-notice.error .ant-message-notice-content {
  background: @red-50;
  color: @text-red-dark;
  & .anticon-close-circle {
    background: @red-200;
    border: 4px solid @red-100;
  }
}

.ant-message-notice.warning .ant-message-notice-content {
  background: @yellow-50;
  color: @yellow-900;
  & .anticon-exclamation-circle {
    background: @yellow-200;
    border: 4px solid @yellow-100;
    path {
      fill: @icon-yellow-dark;
    }
  }
}

.ant-message-notice.info .ant-message-notice-content {
  background: @bg-teal-light;
  color: @teal-900;
  & .anticon-info-circle {
    background: @teal-300;
    border: 4px solid @teal-200;
    path {
      fill: @teal-500;
    }
  }
}

[dir='rtl'] .ant-message-notice-content .ant-message-rtl {
  direction: rtl !important;
}

.br-notify__message__actions {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 16px;
}
