@import './fonts-variables.less';

//font-styles

.font-10 {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0;
}

.font-12 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

.font-13 {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.font-15 {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0;
}

.font-16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-17 {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
}

.font-20 {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
}

.font-24 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0;
}

.font-28 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0;
}

.font-40 {
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0;
}

//text styles
.display-xl {
  .font-40;
  .responsive(@sm,{
    .font-28;
  });
}

.display-lg {
  .font-28;
  .responsive(@sm,{
    .font-24
  });
}

.display-md {
  .font-24;
  .responsive(@sm,{
    .font-20;
  });
}

.display-sm {
  .font-20;
  .responsive(@sm,{
    .font-16;
  });
}

.display-xs {
  .font-18;
}

.sidebar-child-medium,
.sidebar-child,
.caption,
.caption-medium {
  .font-12;
  .responsive(@sm,{
    .font-13;
  });
}

.heading,
.button-lg,
.subtitle-md {
  .font-16;
  .responsive(@sm,{
    .font-17;
  });
}

.subheading {
  .font-12;
  letter-spacing: 0.04em;
  .responsive(@sm,{
    letter-spacing: 4%;
    .font-13;
  });
}

.button,
.body-medium,
.body,
.sidebar-parent {
  .font-14;
  .responsive(@sm ,{
   .font-14;
  });
}

.caption-lg {
  .font-13;
  .responsive(@sm ,{
    .font-14;
   });
}

.subtitle-sm,
.subtitle-sm-secondary {
  .font-10;
}

.title-sm {
  .font-17;
  .responsive(@sm ,{
    .font-15;
   });
}

//configuration related to dashboard language

.display-xl,
.display-lg {
  font-family: @arabic-bold-english-semi-bold;
}

.display-md,
.display-sm,
.subtitle-sm-secondary,
.title-sm {
  font-family: @font-semibold-1;
}

.sidebar-parent {
  font-family: @arabic-semibold-english-medium;
}

.sidebar-child-medium,
.display-xs {
  font-family: @font-medium;
}

.sidebar-child,
.subtitle-md {
  font-family: @font-regular-1;
}

.heading,
.button-lg,
.button,
.body-medium,
.button,
.caption-medium,
.subtitle-sm {
  font-family: @font-semibold-2;
}

.subheading,
.body,
.caption-lg,
.caption {
  font-family: @font-regular-2;
}
