@import 'styles/variables';

.br-prohibited-items-uncoverd-zones__modal {
  .ant-modal-body {
    padding: 40px;
  }

  .ant-modal-content {
    max-width: 720px;
  }

  .br-banner-content__text {
    flex: 1;
  }

  .br-banner-content__parent .br-banner-content {
    padding: 0;
  }
}

.br-prohibited-items-list__item svg {
  width: 24px;
  height: 24px;
}

.br-prohibited-items-list__item svg path {
  fill: @yellow-600;
}

.br-prohibited-items-uncoverd-zones__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.br-prohibited-items-uncoverd-zones__container .ant-collapse {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  width: 100%;
}

.br-prohibited-items-uncoverd-zones__modal
  .br-prohibited-items-uncoverd-zones__container
  .ant-collapse-borderless
  > .ant-collapse-item:not(.ant-collapse-item-active) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.br-prohibited-items-uncoverd-zones__subtitle {
  color: @text-gray;
  margin-top: 4px;
}

.br-prohibited-items-uncoverd-zones__collapse-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.br-prohibited-items-uncoverd-zones__collapse-content {
  display: flex;
  flex-direction: column;
}

.br-prohibited-items-uncoverd-zones__collapse-subtitle {
  color: @text-gray;
}

.br-prohibited-items-uncoverd-zones__modal {
  .ant-collapse-item-active {
    .br-prohibited-items-uncoverd-zones__collapse-icon {
      svg * {
        stroke: @icon-yellow-dark;
      }
    }
  }
}

.br-prohibited-items-uncoverd-zones__modal {
  .ant-collapse-borderless > .ant-collapse-item {
    border-radius: 8px;
    border: 1px solid @gray-200;

    .ant-collapse-header {
      align-items: center;
      gap: 24px;
      padding: 12px 16px;
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    &.ant-collapse-item-active .ant-collapse-header {
      border-bottom: 1px solid @gray-200;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &:not(.ant-collapse-item-active) {
      background-color: @bg-pressed;
    }
  }
}

.br-prohibited-items-uncoverd-zones__modal {
  .ant-modal-footer {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      height: 2px;
    }

    &::before {
      background-color: @bg-gray;
      width: 100%;
    }

    &::after {
      width: 54%;
      background-color: @deep-teal;
    }
  }
}

.br-prohibited-items-list__item-content {
  flex: 1;
}

@media only screen and (max-width: @sm) {
  .br-prohibited-items-uncoverd-zones__modal {
    .ant-modal-body {
      padding: 20px;
      overflow: auto;
      max-height: calc(100vh - 132px);
    }
  }
}
