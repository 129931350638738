@import 'styles/variables';

.br-signup-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 170px;
  box-shadow: @shadow-xs;
  background-color: @white;
}

.br-signup-header__logo svg {
  max-width: 111px;
}

.br-signup-header__actions {
  display: flex;
  align-items: center;
  gap: 26px;
}

.br-signup__track-order.br-button-component.link-color {
  .ant-icon-md:last-child svg > * {
    fill: none;
    stroke: @teal-500;
  }
}

.br-signup__track-order.br-button-component.link-color:hover {
  .ant-icon-md:last-child svg > * {
    fill: none;
    stroke: @teal-700;
  }
}

html[dir='ltr'] .br-signup__language-btn {
  .br-button-component__label {
    font-family: @Arabic-600;
  }
}

@media only screen and (max-width: @lg) {
  .br-signup-header__container {
    padding: 0 100px;
  }
}

@media only screen and (max-width: @sm) {
  .br-signup-header__container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: @xs) {
  .br-signup-header__container {
    align-items: flex-start;
    padding: 20px 16px;
  }

  .br-signup-header__actions {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 16px;
  }
}
