@import 'styles/variables.less';

.br-google-map-card__container {
  margin: 25px 0 28px;
  height: 315px;
}

.br-google-map-card {
  position: relative;
  padding: 12px 16px;
  min-height: 108px;
  cursor: pointer;
}

.br-google-map-card:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid @gray-200;
  border-radius: 4px;
  background-image: linear-gradient(
      90deg,
      #ffffff 0.04%,
      rgba(254, 255, 255, 0.7) 35.99%,
      rgba(250, 253, 254, 0) 99.23%
    ),
    url('../../../assets/bosta-icons/googleMapCard.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.br-google-map-card__error::before {
  border-color: @red-300;
}

html[dir='rtl'] .br-google-map-card:before {
  transform: scaleX(-1);
  background-image: linear-gradient(
      270deg,
      #ffffff 0.04%,
      rgba(254, 255, 255, 0.7) 35.99%,
      rgba(250, 253, 254, 0) 99.23%
    ),
    url('../../../assets/bosta-icons/googleMapCard.svg');
}

.br-google-map-card__icon-container {
  position: absolute;
  right: 26%;
  top: 40%;
}

.br-google-map-card__icon svg {
  width: 28px;
  height: 31px;
}

.br-google-map-card__header {
  margin: 0;
  > div {
    z-index: 2;
  }

  .br-content-header__sub-title {
    color: @text-gray;
  }
}

.br-google-map-card__location {
  border: 1px solid @border-color-20;
  border-radius: 4px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.br-google-map-card__header .br-sidebar-content-header-with-sub-title {
  margin-bottom: 0;
}

.br-google-map-card__location .br-google-map__places-search-box__header,
.br-google-map-card__location .gmnoprint {
  display: none;
}

.br-google-map-card .ant-btn.outline {
  margin-top: 8px;
}

.br-google-map-card .gm-style > div > div {
  background-color: @box-shadow-2;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.br-form-count__optional-label {
  .font({.caption()});
}

.br-google-map-card__error-message {
  color: @red-500;
}

@media only screen and (max-width: @xs) {
  .br-google-map-card__icon-container {
    right: 15%;
    top: 50%;
  }
}
