@import 'styles/variables.less';

.ant-modal-root .ant-modal-wrap.br-google-map-modal .ant-modal {
  max-width: 536px;
  width: 100%;
}

.ant-modal-root
  .ant-modal-wrap.br-google-map-modal
  .ant-modal
  .ant-modal-close {
  display: none;
}

.br-google-map-modal .ant-modal-body {
  padding: 0;
}

.br-content-header.br-google-map-modal {
  padding: 22px 20px;
  box-shadow: @border-bottom;
}

.br-google-map-modal__title {
  margin-bottom: 0;
  color: @text-gray-dark;
}

.br-google-map-modal_location {
  height: 63vh;
}

.br-google-map-modal_location__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  box-shadow: @border-top;
  background: @bg-gray-light;
  border-radius: 0 0 8px 8px;
  .ant-btn:first-child {
    margin-right: 12px;
  }
}
