@import 'src/styles/variables.less';

.br-button-with-box-shadow {
  box-shadow: @shadow-xs;
}

.br-button-without-box-shadow {
  box-shadow: none;
}

.br-button-component {
  display: block;
  padding: 0px;
  border: 0px;
  border-radius: 4px;
  .br-button-component__label,
  span.anticon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-btn-loading-icon {
    position: absolute;
  }
}

.br-button-component.ant-btn > .anticon + span,
.br-button-component.ant-btn > span + .anticon {
  margin: 0px;
}

// Button 3 Sizes (button-sm, button-md, button-lg)
.br-button-component.button-sm.ant-btn {
  height: 28px;
  .br-button-component__label {
    margin-right: 0px;
  }
}

.br-button-component.button-md.ant-btn {
  height: 36px;
  .br-button-component__label {
    margin-right: 0px;
  }
}

.br-button-component.button-lg.ant-btn {
  height: 48px;
  .br-button-component__label {
    margin-right: 0px;
  }
}

// Button with label only for each size!
.br-button-component__with-label.button-sm {
  padding: 4px 8px;
}

.br-button-component__with-label.button-md {
  padding: 8px 16px;
}

.br-button-component__with-label.button-lg {
  padding: 12px 24px;
}

// Button with prefix for each size!
.br-button-component__with-prefix.button-sm {
  padding: 4px 8px 4px 6px;
  > span {
    display: flex;
    justify-content: center;
    margin-right: 4px;
    svg {
      margin: 0px;
    }
  }
}

.br-button-component__with-prefix.button-md {
  padding: 8px 16px 8px 14px;
  > span {
    display: flex;
    justify-content: center;
    margin-right: 6px;
    svg {
      margin: 0px;
    }
  }
}

.br-button-component__with-prefix.button-lg {
  padding: 12px 24px 12px 22px;
  > span {
    display: flex;
    justify-content: center;
    margin-right: 8px;
    svg {
      margin: 0px;
    }
  }
}

// Button with suffix for each size!
.br-button-component__with-suffix.button-sm {
  padding: 4px 6px 4px 8px;
  > span:last-child {
    margin-left: 4px;
    display: flex;
    justify-content: center;
    svg {
      margin: 0px;
    }
  }
}

.br-button-component__with-suffix.button-md {
  padding: 8px 14px 8px 16px;
  > span:last-child {
    margin-left: 4px;
    display: flex;
    justify-content: center;
    svg {
      margin: 0px;
    }
  }
}

.br-button-component__with-suffix.button-lg {
  padding: 12px 22px 12px 24px;
  > span:last-child {
    margin-left: 8px;
    display: flex;
    justify-content: center;
    svg {
      margin: 0px;
    }
  }
}

// Button with icon only for each size!
.br-button-component__with-icon-only.button-sm {
  padding: 4px 8px;
  span,
  span:last-child {
    margin: 0px;
  }
}

.br-button-component__with-icon-only.button-md {
  padding: 8px;
  span,
  span:last-child {
    margin: 0px;
  }
}

.br-button-component__with-icon-only.button-lg {
  padding: 12px;
  span,
  span:last-child {
    margin: 0px;
  }
}

// Button with primary type
.br-button-component.primary svg path {
  fill: @white;
}
.br-button-component_primary-common {
  border-color: @teal-500;
  background-color: @teal-500;
  span {
    color: @white;
  }
}

.br-button-component.primary,
.br-button-component.primary:focus {
  .br-button-with-box-shadow;
  .br-button-component_primary-common;
}
.br-button-component.primary:hover {
  border-color: @teal-600;
  background-color: @teal-600;
  border-color: @teal-600;
  span {
    color: @white;
  }
  .br-button-with-box-shadow;
}
.br-button-component.primary.ant-btn:not([disabled]):active {
  box-shadow: @shadow-xs-green;
  .br-button-component_primary-common;
}
.br-button-component.primary.ant-btn[disabled] {
  border-color: @teal-300;
  background-color: @teal-300;
  border-color: @teal-300;
  span {
    color: @white;
  }
  .br-button-without-box-shadow;
}

// Button with outline type
.br-button-component.outline svg path {
  fill: @teal-500;
}
.br-button-component.outline,
.br-button-component.outline:focus {
  border-color: @teal-500;
  background-color: @white;
  span {
    color: @teal-500;
  }
  .br-button-with-box-shadow;
}
.br-button-component.outline:hover {
  border-color: @teal-500;
  background-color: @teal-100;
  span {
    color: @teal-500;
  }
  .br-button-with-box-shadow;
}
.br-button-component.outline.ant-btn:not([disabled]):active {
  border-color: @teal-500;
  background-color: @white;
  box-shadow: @shadow-xs-green;
  span {
    color: @teal-500;
  }
}
.br-button-component.outline.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @teal-300;
  background-color: @teal-50;
  .br-button-without-box-shadow;
  svg path {
    fill: @teal-300;
  }
  span {
    color: @teal-300;
  }
}

// Button with basic type
.br-button-component.basic svg path {
  fill: @gray-500;
}
.br-button-component.basic,
.br-button-component.basic:focus {
  border-color: @gray-300;
  span {
    color: @gray-900;
  }
  .br-button-with-box-shadow;
}
.br-button-component.basic:hover {
  border-color: @gray-300;
  background-color: @gray-50;
  span {
    color: @gray-900;
  }
  .br-button-with-box-shadow;
}
.br-button-component.basic.ant-btn:not([disabled]):active {
  border-color: @teal-300;
  background-color: @white;
  box-shadow: @shadow-xs-green;
  span {
    color: @gray-900;
  }
}
.br-button-component.basic.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border: @border-all-sides;
  background-color: @gray-50;
  .br-button-without-box-shadow;
  svg path {
    fill: @gray-400;
  }
  span {
    color: @gray-400;
  }
}

// Button with treitary-color type
.br-button-component.treitary-color svg path {
  fill: @teal-500;
}
.br-button-component.treitary-color,
.br-button-component.treitary-color:focus,
.br-button-component.treitary-color.ant-btn:not([disabled]):active {
  border-color: transparent;
  background-color: transparent;
  .br-button-without-box-shadow;
  span {
    color: @teal-500;
  }
}
.br-button-component.treitary-color:hover {
  border-color: @teal-100;
  background-color: @teal-100;
  .br-button-without-box-shadow;
  span {
    color: @teal-500;
  }
}
.br-button-component.treitary-color.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @white;
  background-color: @white;
  .br-button-without-box-shadow;
  svg path {
    fill: @gray-400;
  }
  span {
    color: @gray-400;
  }
}

// Button with treitary-gray type
.br-button-component.treitary-gray svg path {
  fill: @gray-500;
}
.br-button-component.treitary-gray,
.br-button-component.treitary-gray:focus,
.br-button-component.treitary-gray.ant-btn:not([disabled]):active,
.br-button-component.treitary-gray:hover {
  border-color: transparent;
  background-color: transparent;
  padding: 0px;
  .br-button-without-box-shadow;
  span {
    color: @gray-500;
  }
}
.br-button-component.treitary-gray:hover span {
  color: @gray-500;
}
.br-button-component.treitary-gray.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @white;
  background-color: @white;
  .br-button-without-box-shadow;
  svg path {
    fill: @gray-400;
  }
  span {
    color: @gray-400;
  }
}

// Button with link-color type
.br-button-component.link-color svg path {
  fill: @text-teal;
}
.br-button-component.link-color,
.br-button-component.link-color:focus,
.br-button-component.link-color.ant-btn:active,
.br-button-component.link-color:hover,
.br-button-component.link-color.ant-btn.br-button-component__loading,
.br-button-component.link-color.ant-btn[disabled] {
  border-color: transparent;
  background-color: transparent;
  padding: 0px;
  .br-button-without-box-shadow;
  span,
  svg path {
    fill: @text-teal;
    color: @text-teal;
  }
}
.br-button-component.link-color:hover {
  span,
  svg path {
    color: @teal-700;
    fill: @teal-700;
  }
}
.br-button-component.link-color.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  span,
  svg path {
    color: @gray-300;
    fill: @gray-300;
  }
}

// Button with link-ocean type

.br-button-component.link-ocean,
.br-button-component.link-ocean:focus,
.br-button-component.link-ocean.ant-btn:active,
.br-button-component.link-ocean.ant-btn:hover,
.br-button-component.link-ocean.ant-btn.br-button-component__loading,
.br-button-component.link-ocean.ant-btn[disabled] {
  border-color: transparent;
  background-color: transparent;
  border: none !important;
  padding: 0px;
  .br-button-without-box-shadow;
  span,
  svg path {
    // fill: @text-teal;
    color: @teal-700;
  }
}
.br-button-component.link-ocean.ant-btn:hover {
  span,
  svg path {
    color: @teal-700;
    // fill: @teal-700;
  }
}
.br-button-component.link-ocean.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  span,
  svg path {
    color: @gray-300;
    fill: @gray-300;
  }
}

// Button with link-gray type
.br-button-component.link-gray svg path {
  fill: @icon-default;
}
.br-button-component.link-gray,
.br-button-component.link-gray:focus,
.br-button-component.link-gray.ant-btn:active,
.br-button-component.link-gray:hover,
.br-button-component.link-gray.ant-btn.br-button-component__loading,
.br-button-component.link-gray.ant-btn[disabled] {
  border-color: transparent;
  background-color: transparent;
  padding: 0px;
  .br-button-without-box-shadow;
  span,
  svg path {
    fill: @text-gray;
    color: @text-gray;
  }
}
.br-button-component.link-gray:hover {
  span,
  svg path {
    color: @gray-700;
    fill: @gray-700;
  }
}
.br-button-component.link-gray.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  span,
  svg path {
    color: @gray-300;
    fill: @gray-300;
  }
}

// Button with destructive-primary type
.br-button-component.destructive-primary svg path {
  fill: @white;
}
.br-button-component.destructive-primary,
.br-button-component.destructive-primary:focus {
  border-color: @red-500;
  background-color: @red-500;
  .br-button-with-box-shadow;
  span {
    color: @white;
  }
}
.br-button-component.destructive-primary:hover {
  border-color: @red-700;
  background-color: @red-700;
  .br-button-with-box-shadow;
  span {
    color: @white;
  }
}
.br-button-component.destructive-primary.ant-btn:not([disabled]):active {
  border-color: @red-500;
  background-color: @red-500;
  box-shadow: @shadow-xs-red;
  span {
    color: @white;
  }
}
.br-button-component.destructive-primary.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @red-200;
  background-color: @red-200;
  .br-button-without-box-shadow;
  span {
    color: @white;
  }
}

// Button with destructive-basic type
.br-button-component.destructive-basic svg path {
  fill: @red-700;
}
.br-button-component.destructive-basic,
.br-button-component.destructive-basic:focus {
  border-color: @red-50;
  background-color: @red-50;
  .br-button-with-box-shadow;
  span {
    color: @red-700;
  }
}
.br-button-component.destructive-basic:hover {
  border-color: @red-100;
  background-color: @red-100;
  .br-button-with-box-shadow;
  span {
    color: @red-700;
  }
}
.br-button-component.destructive-basic.ant-btn:not([disabled]):active {
  border-color: @red-50;
  background-color: @red-50;
  box-shadow: @shadow-xs-red;
  span {
    color: @red-700;
  }
}
.br-button-component.destructive-basic.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @red-25;
  background-color: @red-25;
  .br-button-without-box-shadow;
  svg path {
    fill: @red-300;
  }
  span {
    color: @red-300;
  }
}

// Button with destructive-treitary type
.br-button-component.destructive-treitary svg path {
  fill: @red-700;
}
.br-button-component.destructive-treitary,
.br-button-component.destructive-treitary:focus,
.br-button-component.destructive-treitary.ant-btn:not([disabled]):active {
  border-color: @white;
  background-color: @white;
  .br-button-without-box-shadow;
  span {
    color: @red-700;
  }
}
.br-button-component.destructive-treitary:hover {
  border-color: @red-50;
  background-color: @red-50;
  .br-button-without-box-shadow;
  span {
    color: @red-700;
  }
}
.br-button-component.destructive-treitary.ant-btn[disabled]:not(
    .br-button-component__loading
  ) {
  border-color: @white;
  background-color: @white;
  .br-button-without-box-shadow;
  svg path {
    fill: @red-300;
  }
  span {
    color: @red-300;
  }
}

// Button with brand type
.br-button-component.brand svg path {
  fill: @white;
}
.br-button-component_brand-common {
  border-color: @bosta-red;
  background-color: @bosta-red;
  span {
    color: @white;
  }
}

.br-button-component.brand,
.br-button-component.brand:focus {
  .br-button-with-box-shadow;
  .br-button-component_brand-common;
}
.br-button-component.brand:hover {
  border-color: @red-700;
  background-color: @red-700;
  span {
    color: @white;
  }
  .br-button-with-box-shadow;
}
.br-button-component.brand.ant-btn:not([disabled]):active {
  box-shadow: @shadow-xs-green;
  .br-button-component_brand-common;
}
.br-button-component.brand.ant-btn[disabled] {
  border-color: @red-200;
  background-color: @red-200;
  border-color: @red-200;
  span {
    color: @white;
  }
  .br-button-without-box-shadow;
}

// table actions button
.br-button-component.table-actions.ant-btn.ant-btn-default {
  border: 1px solid transparent;
  background-color: transparent;
  box-shadow: none;
  height: 28px;
  width: 36px;
  .br-button-without-box-shadow;
}
.br-button-component.table-actions.ant-btn.ant-btn-default:focus,
.br-button-component.table-actions.ant-btn.ant-btn-default:not(
    [disabled]
  ):active,
.br-button-component.table-actions.ant-btn.ant-btn-default:hover {
  border: 1px solid @gray-300;
  box-shadow: 0px 1px 2px rgba(@gray-800, 0.1);
  background-color: @white;
}

// Loading style for all types except primary
.br-button-component.br-button-component__loading:not(.primary) {
  background-color: @gray-100;
  border: 1px solid @gray-100;
  .br-button-without-box-shadow;
}

.br-button-component__hide-onloading {
  opacity: 0;
}

.br-button-component__loading .ant-btn-loading-icon svg {
  margin: 0px;
}

.br-button-component__loading .anticon.anticon-loading.anticon-spin {
  padding: 0px;
  margin: 0px;
}

svg.br-button-component__loading-svg {
  width: 20px;
  height: 20px;
  position: absolute;
  animation: BRButtonLoad 2s linear infinite;
  margin: 0px;

  & .br-button-component__loading-path {
    stroke: @gray-400;
    stroke-linecap: round;
    animation: BRButtonLoadPath 1.5s ease-in-out infinite;
  }
}

@keyframes BRButtonLoad {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes BRButtonLoadPath {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.br-button-component.primary
  svg.br-button-component__loading-svg
  .br-button-component__loading-path {
  stroke: @white;
}
