@import 'styles/variables';

.br-reset-password-container .br-registration-form-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.br-reset-password__subtitle {
  color: @text-gray;
  margin-top: 8px;
}

.br-reset-password_user-section {
  display: flex;
  align-items: center;
  margin: 32px 0;
}

.br-reset-password_user-name-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: @teal-200;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: @teal-700;
}

.br-reset-password_user-data {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
}

.br-reset-password__user-email,
.br-reset-password__password-validation,
.br-reset-password__password-last-validation {
  color: @text-gray;
}

.br-reset-password__password-validation,
.br-reset-password__password-last-validation {
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: @icon-disabled;
    }
  }
}

.br-reset-password__password-validation--is-valid {
  color: @text-gray-dark;
  svg path {
    fill: @green-600;
  }
}

.br-reset-password__password-validation--is-not-valid {
  color: @red-500;
  svg path {
    fill: @red-400;
  }
}

.br-registration-form-card
  .br-reset-password__password-input.ant-row.ant-form-item {
  .ant-form-item-explain {
    margin-top: 8px;
  }
}

.br-reset-password__password-last-validation {
  margin-top: 4px;
}

.br-reset-password__validation-success {
  display: flex;
  align-items: center;
  color: @text-gray-dark;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: @green-600;
    }
  }
}
