@import 'styles/variables';

.br-suggestion-box__modal {
  .ant-row.ant-form-item {
    min-height: auto;
    margin-bottom: 16px;
  }

  .ant-input {
    height: 36px;
  }
}

.br-suggestion-box-modal__subtitle {
  color: @text-gray;
}
