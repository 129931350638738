@import 'styles/variables';

.br-city-area-drawer {
  .ant-drawer .ant-drawer-content-wrapper {
    height: 100% !important;

    .ant-drawer-close {
      display: none;
    }

    .ant-drawer-body {
      padding: 0;
    }
  }
}

.br-city-area-drawer__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-city-area-collapse__search {
  padding: 16px;
  display: flex;
  gap: 16px;
  box-shadow: @border-top;

  .ant-input-affix-wrapper {
    border-radius: 52px;
  }
}

.br-city-area-collapse__cancel-search-btn {
  padding: 0;

  &,
  &:focus,
  &:hover {
    background-color: transparent !important;
    border: unset !important;
  }
}

.br-city-area-collapse {
  .ant-collapse-item.ant-collapse-no-arrow {
    & {
      box-shadow: @border-bottom;
    }

    .ant-collapse-header {
      padding: 8px !important;

      .br-city-area-collapse__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        color: @text-gray-dark;
        .font({.body-medium()});
      }

      .ant-collapse-header-text {
        width: 100%;
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        padding-bottom: 0 !important;
        position: sticky;
        top: 0;
        background-color: @white;

        .br-city-area-collapse__header {
          background-color: @bg-pressed;
          border-radius: 4px;

          .down-arrow {
            transform: scaleY(-1);
          }
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  .br-city-area__no-results-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 48px;
    margin-top: 15%;

    .br-city-area__no-results-title {
      color: @text-gray-dark;
      .font({.heading()});
    }
  }
}

.br-city-area__highlighted-text {
  color: @teal-500;
  background-color: @bg-selected;
  padding: 0;
}

.br-city-area__area {
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &.br-selected-area {
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    background-color: @bg-hovered;
  }

  &.br-uncovered-area {
    color: @text-gray-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: not-allowed;
    &:hover {
      background-color: unset;
    }

    .ant-tag.br-city-area__uncovered-tag {
      height: fit-content;
      margin-right: 0;
      border-radius: 16px;
      text-transform: uppercase;
      .font({.subheading()}) !important;
    }
  }

  &.br-selected-area {
    .br-city-area__selected-indicator {
      display: block;
    }

    .br-city-area__area-text {
      padding: 8px 0px 8px 8px;
      background-color: @bg-selected;
      border-radius: 4px;
      width: 100%;
    }
  }

  .br-city-area__area-container {
    height: 36px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.br-city-area__area__divider {
  display: block;
  height: 1px;
  margin: 0 16px;
  box-shadow: @border-bottom;
}

.br-city-area__selected-indicator {
  background: @teal-500;
  width: 4px;
  height: 36px;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
  display: none;
}

.br-city-area-modal {
  .ant-modal {
    padding-bottom: 0;
    width: 536px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      height: auto;
      padding: 0;

      .ant-modal-title {
        width: 100%;

        .br-city-area-modal__title {
          .font({.display-xs()});
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          padding: 22px 16px;

          .close-icon {
            cursor: pointer;
          }
        }
      }
    }

    .ant-modal-content {
      overflow: hidden;

      .ant-modal-body {
        padding: 0;
        height: 464px;
        overflow-y: auto;
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-city-area-modal {
    &.ant-modal-centered {
      &:before {
        content: unset;
      }
      text-align: unset;
    }

    .ant-modal {
      &,
      .ant-modal-content {
        // height: calc(100vh - calc(100vh - 100%));
        width: 100vw;
        margin: 0;
        top: 0;
      }

      .ant-modal-content {
        border-radius: 0;

        // .ant-modal-body {
        //   height: calc(~"100vh - 190px");
        // }
      }
    }
  }
}
