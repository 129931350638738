@import 'styles/variables.less';

.br-cashout__change-modal {
  .br-cashout__change-info {
    display: flex;
    border: 1px solid @teal-300;
    border-radius: 4px;
    padding: 12px;
    gap: 12px;
    background-color: @teal-100;

    > svg {
      height: 20px;
      width: 20px;
      path {
        fill: @teal-500;
      }
    }
  }

  & p {
    margin: 0;
  }

  .br-cashout__change-info-warning {
    display: flex;
    border: 1px solid @yellow-200;
    border-radius: 4px;
    padding: 12px;
    gap: 12px;
    background-color: @yellow-50;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    > svg {
      height: 20px;
      width: 20px;
      path {
        fill: @yellow-600;
      }
    }
  }

  .br-cashout__change-radio-group {
    margin: 28px 0;
  }

  .br-cashout__change-disabled {
    & .br-cashout__daily-next-day-fee,
    .br-cashout__daily-freq-value {
      opacity: 0.5;
    }
  }

  .br-cashout__change-option-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    & .br-cashout__change-option-info-p1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      & .br-cashout__daily-next-day-fee {
        background-color: @teal-100;
        margin-bottom: 0;
        border-radius: 16px;
        color: @teal-700;
        padding: 8px;
      }

      & .br-cashout__weekly-next-day-fee {
        background-color: @gray-100;
        margin-bottom: 0;
        border-radius: 16px;
        padding: 2px 8px;
        height: 20px;
      }
    }

    & .br-cashout__change-option-info-p2 {
      background-color: @gray-50;
      border: 1px solid @gray-200;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > p {
          width: 50%;
        }

        & .br-cashout__daily-freq-value {
          color: @text-teal;
        }
      }
    }
  }

  .br-chashout__change-modal-footer {
    display: flex;
    gap: 8px;
  }
}
