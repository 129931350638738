@import 'styles/variables';

.br-forget-password-container .br-registration-form-card {
  display: flex;
  flex-direction: column;
}

.br-forget-form-card__new_to_bosta,
.br-forget-form-card__subtitle {
  color: @text-gray;
  flex-wrap: wrap;
}

.br-forget-form-card__subtitle {
  margin-bottom: 32px;
  margin-top: 8px;
}

.br-forget-password__return-to-signin-btn {
  margin-top: 20px;
}

.br-login-form-card__subtitle.br-forget-form-card__new_to_bosta {
  display: flex;
  align-items: center;
  margin-top: 16px;
  .ant-btn {
    margin-left: 4px;
  }
}

.br-forget-password__not-found-user-error {
  color: @red-500;
}

@media only screen and (max-width: @xs) {
  .br-forget-password__return-to-signin-btn,
  .br-forget-form-card__new_to_bosta {
    align-self: center;
  }

  .br-forget-form-card__new_to_bosta {
    margin-top: 48px;
  }
}
