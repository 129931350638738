@import 'styles/variables';

.br-verify-email__modal {
  .ant-modal-content {
    width: 372px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 16px;
  }
}

.br-verify-email__title {
  margin-top: 24px;
  text-align: center;

  > span {
    display: block;
  }
}

.br-verify-email__subtitle {
  color: @text-gray;
  text-align: center;
}

.br-verify-email__actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 26px;
}
