@import 'styles/variables';

.br-pickup-fees__modal {
  .ant-modal {
    max-width: 436px;
  }

  .ant-modal-body {
    padding: 24px;
  }
}

.br-pickup-fees-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > div {
    text-align: center;
  }
}

.br-pickups-fees-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 8px solid @yellow-50;
  background-color: @yellow-100;
  margin-bottom: 8px;

  > svg {
    width: 24px;
    height: 24px;

    > * {
      fill: @icon-yellow-dark;
    }
  }
}

.br-pickups-fees-modal__subtitle {
  color: @text-gray;
}

.br-pickups-fees-modal__pricing {
  color: @text-teal;
}

.br-pickups-fees-modal__footer {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 24px;

  > .ant-btn {
    width: 100%;
  }
}
