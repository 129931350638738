@import 'styles/variables';

.br-signup-partners__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 76px;
  padding: 72px 0;
  background-color: @white;
}

.br-signup-partners__logos {
  display: flex;
  align-items: center;
  gap: 60px;
}

html[dir='rtl'] .br-signup-partners__logos {
  flex-direction: row-reverse;
}

@media only screen and (max-width: @sm) {
  .br-signup-partners__container div.br-signup-partners__logos {
    flex-direction: column;
  }
}
