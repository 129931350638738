@import 'styles/variables';

.br-signup-pricing__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 156px;
}

.br-signup-pricing__subtitle {
  color: @text-gray;
  margin-bottom: 32px;
  margin-top: 8px;
}

.br-signup-pricing__table {
  &.br-table-component__no-selection
    .br-table
    .ant-table-thead
    > tr
    > th:first-child {
    padding: 32px 12px;
  }

  &.br-table-component__no-selection
    .br-table
    .ant-table-container
    table
    > thead
    > tr:first-child
    th:not(:first-child) {
    background-color: @bg-red-light;
  }

  &.br-table-component__no-selection .br-table {
    .ant-table-tbody > tr > td:first-child,
    .ant-table-thead > tr > th:first-child {
      border-right: 1px solid @gray-200;
    }
  }
}

.br-signup-pricing__features {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 48px;
  margin-top: 24px;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

@media only screen and (max-width: @sm) {
  .br-signup-pricing__container {
    padding: 20px;

    .br-signup-pricing__table {
      width: 100%;
    }
  }
}
