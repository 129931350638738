@font-face {
  font-family: 'display-sm/bold';
  src: url(../assets/fonts/English/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'display-xs/Semibold';
  src: url(../assets/fonts/English/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'text/Medium';
  src: url(../assets/fonts/English/SF-Pro-Text-Medium.otf);
}

@font-face {
  font-family: 'text/regular';
  src: url(../assets/fonts/English/SF-Pro-Text-Regular.otf);
}

/* rtl fonts */
@font-face {
  font-family: 'Cairo, Bold';
  src: url(../assets/fonts/Arabic/Cairo-Bold.ttf);
}

@font-face {
  font-family: 'Cairo, SemiBold';
  src: url(../assets/fonts/Arabic/Cairo-SemiBold.ttf);
}

@font-face {
  font-family: 'Cairo, Black';
  src: url(../assets/fonts/Arabic/Cairo-Black.ttf);
}

@font-face {
  font-family: 'Cairo, Regular';
  src: url(../assets/fonts/Arabic/Cairo-Regular.ttf);
}
