@import 'styles/variables';

.br-product-modal {
  .ant-modal {
    min-width: 536px;
  }

  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
    .br-content-header {
      padding: 16px 20px;
      margin-bottom: 0;
      box-shadow: @border-bottom;
    }
    .ant-row.ant-form-item {
      min-height: 0;
      margin-bottom: 0;
    }
    .ant-divider.ant-divider-horizontal {
      margin: 16px 0;
    }
  }
}

.br-product-modal .br-product-modal__form-item-container {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  &:nth-last-child(4) {
    margin-bottom: 16px;
  }
  .br-product-modal__form-item {
    display: flex;
    gap: 16px;
    width: 100%;
    .br-product-modal__form-name {
      flex-grow: 1;
    }
    .br-product-modal__form-price {
      width: 108px;
      flex: none;
      .ant-input-number-affix-wrapper {
        width: 100%;
        height: 36px;
        padding: 0 8px;
        border-radius: 4px;
      }
    }
  }

  > span {
    position: relative;
    width: 36px;
    svg {
      position: absolute;
      right: 25%;
      top: 36px;
    }
  }

  .br-product-modal__form-add-product {
    height: 20px;
  }
}

.br-product-modal .br-product-modal__form {
  padding: 16px 20px;
  max-height: 72vh;
  overflow: auto;
  div:nth-last-child(3) {
    .ant-divider.ant-divider-horizontal {
      display: none;
    }
  }
}

.br-product-modal__more-info {
  display: flex;
  gap: 14px;
  background-color: @teal-100;
  border: 1px solid @teal-300;
  border-radius: 4px;
  padding: 16px;
  margin-top: 7px;
  svg path {
    fill: @teal-500;
  }
}

.br-product-modal__footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background-color: @bg-gray-light;
  box-shadow: @border-top;
  border-radius: 0 0 8px 8px;
  gap: 12px;
}

@media only screen and (max-width: @xs) {
  .br-product-modal {
    .ant-modal {
      min-width: unset;
    }

    .ant-modal-content {
      width: 100%;
    }
  }
  .br-product-modal .br-product-modal__form {
    padding: 16px 0;
    > div .br-product-modal__form-item-container {
      padding: 0 16px;
    }
    div:nth-last-child(3) {
      .ant-divider.ant-divider-horizontal {
        display: block;
      }
    }
    .br-product-modal__form-add-product {
      margin-left: 16px;
    }
    .br-product-modal__more-info {
      margin: 0 16px;
    }
  }
}
