@import 'styles/variables.less';

.br-teams-view-permissions .ant-modal-content {
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
}

.br-teams-view-permissions .ant-modal-content button {
  display: block;
}

.br-teams-view-permissions .ant-modal-content button svg {
  height: 14.17px;
  width: 14.17px;
}

.br-teams-view-permissions .br-sidebar-content__sub-title span {
  color: @text-color-29;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: bold;
}

.br-teams-view-permissions__modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-teams-view-permissions__modal__content .anticon svg {
  max-width: 100%;
  max-height: 100%;
  height: 159px;
  width: 180px;
  margin-top: 10px;
}

.br-teams-view-permissions__modal .ant-divider-horizontal {
  margin: 11px 0;
}

.br-teams-view-permissions__modal__content_title {
  text-align: center;
  margin-top: 6px;
}

.br-teams-view-permissions__modal__content_title
  .br-sidebar-content__sub-title {
  color: @text-color-31;
}

.br-teams-view-permissions__modal__content_title
  .br-sidebar-content-header-with-sub-title {
  margin-bottom: 0px;
}

.br-teams-view-permissions__modal__content__footer {
  margin-left: auto;
}

.br-teams-view-permissions__modal__content__list-permission {
  display: flex;
  flex-direction: column;
  align-self: end;
}

.br-teams-view-permissions__modal__content__list-permission__heading {
  font-weight: bold;
  font-size: 14px;
  color: @text-color-31;
  font-family: @font-500;
  margin-bottom: 12px;
}

.br-teams-view-permissions__modal__content__list-permission_item {
  display: flex;
}

.br-teams-view-permissions__modal__content__list-permission_item
  div:first-child {
  margin-right: 10px;
}

.br-teams-view-permissions__modal__content__list-permission_item
  div:last-child {
  font-size: 12px;
  color: @text-color-31;
  place-self: flex-end;
}

.br-teams-view-permissions__modal__content
  .br-teams-view-permissions__modal__content__list-permission_item
  .anticon
  svg {
  width: 12px;
  height: 12px;
  margin-top: unset;
}
