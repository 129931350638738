@import 'styles/variables';

.br-signup__container {
  display: flex;
  margin-top: 1px;
}

@media only screen and (max-width: @sm) {
  .br-signup__container {
    flex-direction: column-reverse;
  }
}
