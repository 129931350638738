@import 'styles/variables';

.br-insurance-card__container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  align-self: stretch;
  background: white;
  width: 280px;
  border-radius: 12px;
  border: 1px solid @gray-200;
  box-shadow: 0px 0px 1px 1px @gray-200, 0px 4px 4px 0px rgba(@gray-800, 0.05);

  .br-insurance-card__header {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 16px;

    &-title {
      .font({.display-sm()});
      white-space: nowrap;
    }

    &-tag {
      .ant-tag.br-tag.br-tag-green {
        .font({.caption-lg()});
      }
    }
  }

  .br-insurance-card__content {
    display: flex;
    flex-direction: column;

    .br-insurance-card__offer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 20px;

      &-title {
        .font({.body()});
        color: @gray-500;
      }

      > div {
        display: flex;
        gap: 8px;
        align-items: baseline;

        span:first-child {
          .font({.display-md()});
        }

        span:last-child {
          .font({.caption()});
        }
      }
    }

    .br-insurance-card__info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;

      &-title {
        .font({.body-medium ()});
      }

      ul {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-top: 16px;
        margin-bottom: 0;
        gap: 12px;
        .font({.caption-lg()});

        .br-insurance-modal__info-features-premium-plan {
          list-style-type: none;
        }
      }

      .br-insurance-modal__default-plan-bullets {
        li {
          display: list-item;
          content: '';
          list-style-type: disc;
          color: @gray-500;
          margin-left: 24px;

          > span {
            color: @gray-500;
            > span {
              font-weight: bold;
            }
          }
        }
      }

      .br-insurance-modal__premium-plan-bullets {
        display: flex;

        .br-insurance-modal__info-features-premium-plan {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          > svg {
            display: flex;
            flex-shrink: 0;
            > * {
              fill: @green-600;
            }
          }
        }

        li:first-child .br-insurance-modal__info-single-feature {
          span {
            font-weight: bold;
            color: black;
          }
        }

        li:last-child .br-insurance-modal__info-single-feature {
          span {
            color: @teal-500;
          }
          .insurance-terms-link {
            display: inline;
            cursor: pointer;
          }
          button {
            margin: 0;
            padding: 0;
            border: none;
            height: auto;
          }
        }
      }
    }
  }
  .br-insurance-card__current-plan-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 15px;
    height: 36px;
    text-align: center;
    color: @gray-500;
    width: 100%;
    .font({.button()});
  }

  .br-insurance-card__btn-tooltip {
    max-width: 350px;
    width: 100%;
    .ant-tooltip-inner {
      text-align: center;
    }
  }
  .ant-btn:disabled {
    cursor: pointer;
    width: 100%;
  }
}

html[dir='rtl']
  .br-insurance-card__container
  .br-insurance-card__pricing-section {
  .br-insurance-card__pricing .offer-price {
    flex-direction: row-reverse;
  }
  .br-insurance-card__before-price {
    flex-direction: row-reverse;
  }
}

@media only screen and (max-width: @xs) {
  .br-insurance-card__container {
    width: 275px;

    .br-insurance-card__btn-tooltip {
      max-width: 245px;
    }
  }
}
