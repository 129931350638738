@import 'styles/variables.less';

.br-form-count__label-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  height: 20px;
  margin-bottom: 4px;
  align-items: center;
}

.br-form-count__optional-label {
  color: @text-gray-light;
  .font({.caption()});
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
  padding: 2px 4px;
}

.br-form-count__optional-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.br-form-count__label {
  color: @text-gray-dark;
  display: flex;
  align-items: center;
}

.br-form-count__count-label {
  color: @text-gray-light;
}

.br-form-count__count-label-exceed span {
  color: @red-500;
}

@media only screen and (max-width: 425px) {
  .br-form-count__label-container {
    height: unset;
  }
}
