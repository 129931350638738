@import 'styles/variables';

.br-smart-sticker-scan-modal {
  .ant-modal-body {
    padding: 24px;
  }
}

.br-smart-sticker-scan-modal__header {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: @bg-teal-light;
  border-radius: 8px;
  padding: 16px 0;
}

.br-smart-sticker-scan-modal__title {
  margin-bottom: 16px;
}

.br-smart-sticker-scan-modal__subtitle {
  margin-top: 16px;
  color: @text-gray;
}

.br-smart-sticker-scan-modal__apps-icons {
  display: flex;
  gap: 13px;
}

.br-smart-sticker-scan-modal__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;

  > span {
    color: @text-gray;
  }
}

.br-smart-sticker-scan-modal__scan-title {
  margin: 16px 0;
}

.br-smart-sticker-scan-modal__footer {
  .ant-btn {
    width: 100%;
  }
}
