@import 'styles/variables';

.br-international-shipping-destination {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  background-color: @green-25;

  > span {
    color: @text-gray;
    padding-top: 16px;
  }
}

.br-international-shipping-destination__selector {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: -12px;
}

html[dir='rtl'] .br-international-shipping-destination__selector {
  > .anticon svg {
    transform: scale(-1);
  }
}
