@import 'styles/variables';

.br-insurance-terms-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.ant-modal-centered .ant-modal.br-insurance-terms-modal-container {
  display: contents;

  .ant-divider-horizontal {
    margin: 0;
  }

  .ant-modal-body {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .br-insurance-terms-modal__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
    margin: @spacing-md 20px;
    padding: 6px 0;
    .font({.display-xs() });

    & > svg {
      cursor: pointer;
    }
  }

  .br-insurance-terms-modal__content {
    display: flex;
    flex-direction: column;
    padding: @spacing-lg @spacing-xl @spacing-lg @spacing-lg;
    gap: @spacing-md;
    overflow-y: auto;
    max-height: 465px;
    height: 100%;
    max-width: 540px;
    width: 100%;

    ::-webkit-scrollbar {
      border-radius: 4px;
      border: none;
    }

    .br-insurance-terms-modal__section {
      display: flex;
      flex-direction: column;
      gap: @spacing-sm;
      text-align: start;
      .font({.body() });

      &-bullets {
        li {
          color: @gray-500;
        }
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-insurance-terms-modal-container .br-insurance-terms-modal__content {
    padding: @spacing-lg;
  }

  .br-insurance-terms-modal-container
    .ant-modal-content
    .br-insurance-terms-modal__agree {
    width: 100%;
  }
}

@media only screen and (max-width: @xs) {
  .br-insurance-terms-modal .ant-modal-footer {
    border-radius: 0px 0px 8px 8px;
  }
}
