@import 'styles/variables';

.br-prepare-orders-modal .ant-modal-body {
  padding: 0;
}

.br-prepare-orders-modal__awb-view .ant-modal-body {
  height: 100%;
  max-height: 532px;
  overflow: auto;
}

.br-prepare-orders-modal__empty-state .ant-modal-body {
  height: 600px;
  overflow: auto;

  .br-empty-state__image {
    padding: 0;
    box-shadow: none;
  }

  .br-table-component__empty-state {
    padding: 0;
    box-shadow: none;
  }
}

.br-prepare-orders-modal .ant-modal-header .ant-modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  .anticon svg {
    margin: 0;
  }
}

.br-prepare-orders-modal .ant-modal-footer {
  justify-content: space-between;
  gap: 24px;

  .ant-checkbox-wrapper {
    align-items: center;
    .font({.button()});
    .ant-checkbox {
      height: 20px;
    }
  }

  .ant-btn {
    flex: 1;
  }
}

.br-prepare-orders__title-and-steps {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.br-prepare-orders__title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 0;
}

.br-prepare-orders__icon.anticon {
  margin-bottom: 16px;
  width: 72px;
  height: 72px;
  background-color: @gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;

  > svg {
    width: 32px;
    height: 32px;
  }
}

.br-prepare-orders__steps-section {
  display: flex;
  gap: 8px;
  padding: 0 16px 24px;
  max-height: 396px;
  overflow: auto;
}

.br-prepare-orders__stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 96px;
  height: 384px;
  width: 36px;
  background: linear-gradient(
    180deg,
    @teal-200 61.61%,
    rgba(217, 244, 248, 0) 100%
  );
  padding: 4px;
  border-radius: 24px;

  > span {
    border-radius: 21px;
    background-color: @white;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.br-prepare-orders__steps-section:has(
    .br-prepare-orders-modal__smart-stickers
  ) {
  .br-prepare-orders__stepper > span:nth-child(2n) {
    margin-bottom: 45px;
  }
}

.br-prepare-orders__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  box-shadow: @border-top;

  .ant-btn.basic svg path {
    stroke: @icon-default;
  }
}

.br-prepare-orders__steps-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .br-prepare-orders__step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: @bg-hovered;
      .br-prepare-orders__step-info {
        .anticon {
          path {
            fill: @icon-teal;
          }

          &.br-prepare-orders__transparent-icon {
            path {
              fill: transparent;
              stroke: @icon-teal;
            }
          }
        }
      }
    }

    .br-prepare-orders__step-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;
      align-items: flex-start;

      .anticon {
        margin-bottom: 4px;

        &.br-prepare-orders__transparent-icon {
          path {
            fill: transparent;
          }
        }
      }
    }
  }
}

.br-prepare-orders-modal__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.br-prepare-orders-modal
  .ant-spin-nested-loading.br-prepare-orders-modal__delivery-loading {
  .ant-spin-container {
    gap: 8px;

    .ant-checkbox-wrapper {
      height: 100px;
      border-radius: 4px;
      padding: 12px;

      .br-prepare-orders-modal__delivery-tracking {
        height: 22px;
        .br-button-component__label {
          color: @text-gray-dark;
        }
      }

      &:hover {
        background-color: @bg-hovered;
        .br-prepare-orders-modal__delivery-tracking
          .br-button-component__label {
          color: @text-teal;
        }
      }

      &-checked {
        background-color: @bg-teal-light;
        .br-prepare-orders-modal__delivery-tracking
          .br-button-component__label {
          color: @text-teal;
        }
      }

      > span:not(.ant-checkbox) {
        flex: 1;
        .br-prepare-orders-modal__delivery-date-cod {
          margin-bottom: 16px;
        }

        .br-prepare-orders-modal__delivery-profile {
          gap: 6px;
        }
      }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}

.br-prepare-orders-modal__scroll-container {
  max-height: 500px;
  overflow: auto;
}

.br-prepare-orders-modal__infinte-scroll {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
}

.br-prepare-orders-modal .br-prepare-orders-modal__text-gray {
  color: @text-gray;
}

.br-prepare-orders-modal__smart-stickers {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
  padding: 0 8px;
  background-color: @gray-50;
  border-radius: 4px;
  color: @text-gray-dark;

  & > svg {
    max-width: 18px;
    margin-right: 2px;
  }
}

@media only screen and (max-width: @xs) {
  .br-prepare-orders__stepper {
    gap: 144px;
    height: 486px;
  }

  .br-prepare-orders__steps-section {
    padding: 0 16px 16px;
  }

  .br-prepare-orders__steps-container {
    gap: 16px;
    .br-prepare-orders__step {
      flex-direction: column;
      align-items: flex-start;

      .br-prepare-orders__step-info {
        .br-prepare-orders-modal__small-desc {
          max-width: 260px;
        }
      }
    }
  }
}
