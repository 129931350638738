@import 'styles/variables';

.br-signup-insurance__container {
  display: flex;
  flex-direction: column;
  max-width: 1088px;
  width: 100%;
  padding: 40px;
  background: white;
  gap: 32px;

  .br-signup-insurance__info {
    display: flex;
    flex-direction: column;
    max-width: 487px;
    width: 100%;
    gap: 4px;

    &-title {
      .font({.display-md()});
    }

    &-subtitle {
      color: @gray-500;
      .font({.body()});
    }
  }
  .ant-spin-nested-loading,
  .ant-spin-container {
    flex-direction: row;
  }

  .br-signup-insurance__plans .ant-spin-container {
    display: flex;
    gap: 24px;
  }
}

@media only screen and (max-width: @lg) {
  .br-signup-insurance__container {
    padding: 24px 16px;
    gap: 16px;

    .br-signup-insurance__plans .ant-spin-container {
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
