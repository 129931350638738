@import 'styles/variables.less';

.br-banner-content__parent {
  width: 100%;
  position: relative;
}

.br-banner-content.br-banner-warning {
  border: 1px solid @tag-light-yellow-text-color;
  background-color: @tag-light-yellow-background-color;
  .br-banner-content__text-container > svg path {
    fill: @icon-yellow-dark;
  }
}

.br-banner-content.br-banner-danger {
  background-color: @red-50;
  border: 1px solid @red-200;
  .br-banner-content__text-container > svg path {
    fill: @icon-red-dark;
  }
}

.br-banner-content.br-banner-success {
  background-color: @green-50;
  border: 1px solid @green-200;
  .br-banner-content__text-container > svg path {
    fill: @icon-green;
  }
}

.br-banner-content.br-banner-info {
  background-color: @gray-50;
  border: 1px solid @gray-200;
  .br-banner-content__text-container > svg path {
    fill: @icon-default;
  }
}

.br-banner-content.br-banner-teal {
  background-color: @teal-100;
  box-shadow: inset 0px -3px 0px @teal-300;
  .br-banner-content__text-container > svg {
    path {
      fill: @icon-teal;
    }
  }
}

.br-banner-content__parent .br-banner-content {
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.br-banner-content__parent .br-banner-fixed {
  padding: 12px 24px;
}

.br-banner-content:not(.br-banner-fixed) .br-banner-content__inner-container {
  padding: 10px 12px;
}

.br-banner-content .br-banner-content__inner-container {
  justify-content: space-between;
}

.br-banner-content__parent
  .br-banner-content
  .br-banner-content__buttons-container {
  display: flex;
  margin: 12px 0px 0px 28px;
  button:nth-child(1) {
    span {
      width: fit-content;
    }
  }
  button:nth-child(2) {
    margin-left: 8px;
  }
}

.br-banner-content__parent
  .br-banner-content
  .br-banner-content__dismiss-icon:hover {
  border-color: transparent;
  background-color: transparent;
}

.br-banner-content__text-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.br-banner-content-press-release .br-banner-content__text-container,
.br-banner-content__inner-container-no-subtitle,
.br-banner-content__inner-container-no-subtitle .br-banner-content__text-title,
.br-banner-content__inner-container-no-subtitle
  .br-banner-content__text-container {
  display: flex;
  align-items: center;
}

.br-banner-content__text {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  & .br-banner-content__text-subtitle,
  .br-banner-content__text-title {
    margin: 0;
  }

  & .br-banner-content__text-title {
    color: @gray-900;
  }
}

.br-banner-content__text__dismiss-icon-responsive {
  display: none;
}

// Banner is Fixed
.br-banner-content.br-banner-fixed.br-banner-info {
  box-shadow: inset 0px -3px 0px @gray-200;
}
.br-banner-content.br-banner-fixed.br-banner-danger {
  box-shadow: inset 0px -3px 0px @red-200;
}
.br-banner-content.br-banner-fixed.br-banner-success {
  box-shadow: inset 0px -3px 0px @green-200;
}
.br-banner-content.br-banner-fixed.br-banner-warning {
  box-shadow: inset 0px -3px 0px @yellow-200;
}

.br-banner-content.br-banner-fixed {
  .br-banner-content__buttons-container {
    margin: 0px 16px 0px 0px;
  }
  border: 0px;
  border-radius: 0px;
}

.br-banner-email-address {
  color: @text-gray;
  word-break: break-word;
}

.br-banner-content__parent
  .br-banner-content
  .br-banner-content__buttons-container.br-banner__bank-info-actions {
  flex-direction: row;
  justify-content: unset;
  > button.ant-btn.ant-btn-default.br-button-component {
    width: unset;
    &:first-of-type {
      margin-left: 30px;
    }
    &:last-of-type {
      margin-left: 8px;
    }
  }
}

@media only screen and (max-width: @md) {
  .br-banner-content__text {
    width: 90%;
  }
}

@media only screen and (max-width: @sm) {
  .br-banner-content__text {
    width: 100%;
  }
  .br-banner-content__inner-container-no-subtitle
    .br-banner-content__text-container {
    display: flex;
    align-items: center;
    .br-banner-content__text-title {
      display: flex;
      align-items: center;
    }
  }
  .br-banner-content__text > div.display-flex {
    justify-content: space-between;
  }
  .br-banner-content__text__dismiss-icon-responsive {
    display: flex;
    justify-content: center;
    button {
      margin: 0px;
    }
  }

  .ant-btn:not(.ant-btn-circle).br-banner-content__dismiss-icon {
    display: none;
  }

  .br-banner-content__parent .br-banner-content {
    display: flex;
    flex-direction: column;
  }

  .br-banner-content__parent
    .br-banner-content
    .br-banner-content__buttons-container {
    width: 100%;
    margin-left: 0px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-right: 0;
    margin-top: 12px;
    button.ant-btn:first-child {
      margin-top: 0;
    }
    button.ant-btn:last-child {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .br-banner-content__parent
    .br-banner-content
    button:not(.br-banner-content__text__dismiss-icon) {
    margin-top: 10px;
    width: 100%;
  }
}
