@import 'styles/variables';

.br-info-banner {
  display: flex;
  gap: 10px;
  background-color: @teal-100;
  padding: 15px;
  border: 1px solid @teal-300;
  border-radius: 4px;
  text-align: start;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: @icon-teal;
    }
  }
}
