@import 'styles/variables';

.br-insurance-details-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;

  .br-insurance-details-modal__header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-bottom: @spacing-lg;

    .br-insurance-details-modal__info-title {
      .font({.display-xs()});
      white-space: nowrap;
    }
  }

  .br-insurance-details-modal__content {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: @spacing-xs;
    padding: @spacing-md;
    background: @green-50;
    align-items: self-start;
    margin-bottom: @spacing-lg;

    &-default-plan {
      background: @yellow-50;
    }

    ul {
      padding-left: 0;
      margin-top: @spacing-lg;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      &.br-insurance-details-modal__default-plan-bullets {
        gap: @spacing-xs;
      }
      &.br-insurance-details-modal__premium-plan-bullets {
        gap: @spacing-md;
      }
    }

    .br-insurance-details-modal__default-plan-bullets {
      .font({.body()});

      li::before {
        content: '- ';
      }
      li span {
        color: @gray-500;
      }
    }

    .br-insurance-details-modal__premium-plan-bullets {
      display: flex;
      .font({.caption-lg()});

      .br-insurance-details-modal__info-features-premium-plan {
        display: flex;
        align-items: flex-start;
        gap: @spacing-sm;

        > svg {
          display: flex;
          flex-shrink: 0;
          > * {
            fill: @green-600;
          }
        }
      }

      li:first-child .br-insurance-details-modal__info-single-feature {
        span {
          font-weight: bold;
          color: black;
        }
      }

      li:last-child .br-insurance-details-modal__info-single-feature {
        span {
          color: @teal-500;
        }
        .insurance-terms-link {
          display: inline;
          cursor: pointer;
        }
      }
    }
  }
}

.br-insurance-details-modal__warning {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: @spacing-md;
  color: @gray-500;
  align-items: center;
  margin-bottom: @spacing-xl;
  .font({.body-medium()});
}

.br-insurance-details-modal__actions {
  width: 100%;
  display: flex;
  gap: @spacing-md;
  justify-content: space-between;
  button {
    width: 100%;
  }
}

html[dir='rtl']
  .br-insurance-details-modal__container
  .br-insurance-details-modal__pricing-section {
  .br-insurance-details-modal__pricing .offer-price {
    flex-direction: row-reverse;
  }
  .br-insurance-details-modal__before-price {
    flex-direction: row-reverse;
  }
}

html[dir='rtl'] .br-insurance-details-modal__actions {
  flex-direction: row-reverse;
}

@media only screen and (max-width: @sm) {
  .br-insurance-details-modal__warning {
    > span:first-child {
      padding: 10px;
    }
  }
}
