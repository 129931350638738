@import 'styles/variables.less';

.br-google-map__places-search-box__list ul {
  padding: 8px;
  margin: 0;
}

.br-google-map__places-search-box__list li {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 4px;
  cursor: pointer;
  &:hover {
    background-color: @bg-hovered;
  }
}

.br-google-map__places-search-box__list li div {
  display: flex;
}

.br-google-map__places-search-box__list li div span.body {
  padding-left: 9px;
  color: @text-gray-dark;
}

.br-google-map__places-search-box__list li > span {
  padding-left: 29px;
  width: 100%;
  max-width: 393px;
  color: @text-gray-light;
}

.br-google-map__places-search-box__header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
  width: 100%;
  padding: 0 12px;
}

.br-google-map__places-search-box .ant-btn svg {
  margin: 0;
}

.br-google-map
  .ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child {
  left: 0;
  background-color: transparent;
}

.br-google-map__places-search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  top: 15px;
}

.display-none {
  display: none;
}

.br-google-map__places-search-box__search {
  width: 472px;
  z-index: 2;
}

.br-google-map__places-search-box__list {
  background-color: @background-color;
  box-shadow: 0px 4px 3px 0px @box-shadow;
  border-radius: 4px;
  max-height: 292px;
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
  }
}

.br-google-map__places-search-box__learn-more {
  margin-left: 4px;
}

.br-google-map__places-search-box .ant-input-affix-wrapper {
  width: 100%;
  height: 36px;
  min-height: unset;
  input {
    min-height: unset;
  }

  .ant-input-clear-icon {
    display: flex;
  }
}

.anticon.br-google-map__places-search-box__my-location-icon {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 36px;
  width: 36px;
  background-color: @background-color;
  border-radius: 4px;
  position: absolute;
  top: 208px;
  right: 14px;
}

.anticon-aim.br-google-map__places-search-box__my-location-icon svg {
  fill: @icon-teal;
}

html[dir='rtl'] .anticon.br-google-map__places-search-box__my-location-icon {
  left: 14px;
}

.br-google-map__places-search-box
  .br-google-map__places-search-box__my-location-icon-error {
  position: absolute;
  top: 113px;
}

.br-google-map .ant-input-affix-wrapper {
  box-shadow: none;
  + .ant-input-group-addon
    > .ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    border-left: none;
    border-radius: 0 4px 4px 0;
  }
}

.br-google-map
  .ant-input-search
  .ant-input-group
  .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 4px 0 0 4px;
  border-right: none;
  &:hover {
    border: 1px solid @teal-400;
    border-right: none;
  }
}

.br-google-map span.ant-input-affix-wrapper.ant-input-affix-wrapper:hover {
  + span.ant-input-group-addon
    > button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    border: 1px solid @teal-400;
    border-left: none;
  }
}

.br-google-map span.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  box-shadow: @shadow-xs-green;
  clip-path: inset(-4px 0px -4px -4px);
  border: 1px solid @teal-400;
  + span.ant-input-group-addon
    > button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    clip-path: inset(-4px -4px -4px 0);
    box-shadow: @shadow-xs-green;
    border: 1px solid @teal-400;
    border-left: none;
  }
}

.br-google-map
  .br-google-map__places-search-box__has-input
  span.ant-input-affix-wrapper {
  box-shadow: @shadow-xs-green;
  clip-path: inset(-4px 0px -4px -4px);
  border: 1px solid @teal-400;
  + span.ant-input-group-addon
    > button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
    box-shadow: @shadow-xs-green;
    background: @teal-500;
    border: 1px solid @teal-400;
    border-left: none;
    > span > svg {
      color: @white;
    }
  }
}

.br-google-map
  span.ant-input-affix-wrapper.ant-input-affix-wrapper
  + span.ant-input-group-addon
  > button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button {
  transition-timing-function: ease;
  clip-path: inset(-4px -4px -4px 0);
}

.br-google-map
  span.ant-input-affix-wrapper.ant-input-affix-wrapper
  + span.ant-input-group-addon
  > button.ant-btn.ant-btn-default.ant-btn-icon-only.ant-input-search-button:hover {
  border: 1px solid @gray-300;
  border-left: none;
}

@media only screen and (max-width: @xs) {
  .br-google-map__places-search-box__search {
    width: 100%;
  }
}
