@import 'styles/variables';

.br-activation-step-progress-dot__not-active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: @gray-200;
  color: @text-gray;
}

.br-activation-step-progress-dot__active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: @deep-teal;
  color: @white;
  box-shadow: @shadow-xs-green;
}

.br-activation-step-progress-dot__finished {
  svg > * {
    fill: @icon-subdued;
  }
}
