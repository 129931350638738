@import 'styles/variables';

.ant-checkbox-wrapper
  span
  .br-form__bulcky-checkbox-title-wrapper
  .form__bulky-subtitle {
  color: @gray-500;
}

.br-request-pickup-modal .ant-modal {
  width: 580px;
  .ant-modal-body {
    padding: 0;
  }
  .br-content-header {
    margin-bottom: 0;
    padding: 16px 20px;
    box-shadow: @border-bottom;
    .br-sidebar-content__sub-title {
      color: @text-gray;
    }
  }
}

.number-of-parcels-wrapper {
  width: 100%;
}
.ant-select-dropdown .br-create-edit-pickup__location-drop-down-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.add-person-button {
  margin-top: 18px;
}

.sub-buessniess {
  height: 72px;
}

.br-form-row__checkboxes-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.br-form-row__checkboxes-wrapper > * {
  max-width: 49%;
  height: 36px;
}

.br-form-row__checkboxes-wrapper label {
  box-shadow: @shadow-xs;
  border: 1px solid @gray-200;
  padding: 8px;
  height: 36px;
  width: 100%;
  border-radius: 4px;
}

.br-request-pickup-modal__form__bulky label {
  box-shadow: @shadow-xs;
  border: 1px solid @teal-200;
  padding: 8px;
  height: 55px;
  width: 100%;
  border-radius: 4px;
  background-color: @teal-100;
  margin-bottom: 15px;
}

.br-request-pickup-modal__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 20px;
  background-color: @bg-gray-light;
  box-shadow: @border-top;
  border-radius: 0 0 8px 8px;
}

.prohibited-items-tooltip .ant-tooltip-inner {
  width: 170px;
}

.br-create-edit-pickup__number-of-parcels-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.br-create-edit-pickup__number-of-parcels-header span {
  color: @teal-500;
  cursor: pointer;
}

.br-request-pickup-modal__form__repeat-pickups-container {
  display: flex;
  flex-direction: column;
  margin: -8px 0 20px 0;
  .ant-checkbox-wrapper {
    align-items: center;
    .ant-checkbox {
      top: 0;
    }
    span:last-child {
      color: @text-gray-dark;
    }
  }

  .br-request-pickup-modal__form__repeat-pickups-subtitle {
    color: @text-gray;
    margin-left: 24px;
  }

  .br-request-pickup-modal__form__repeat-pickups.br-request-pickup-modal__form__repeat-pickups__visible {
    height: 100px;
    opacity: 1;
    margin: 12px 0 8px;
  }

  .br-request-pickup-modal__form__repeat-pickups {
    display: flex;
    flex-direction: column;
    margin: 0;
    opacity: 0;
    height: 0;
    transition: height 350ms ease 0s, opacity 350ms ease 0s,
      margin 350ms ease 0s;
    box-shadow: @border-bottom;
    .br-request-pickup-modal__form__repeat-pickups-options {
      display: flex;
      align-items: center;
      margin: 0 24px 12px 24px;
      .br-request-pickup-modal__form__repeat-pickups-option__title {
        color: @text-gray;
        width: 64px;
        margin-right: 4px;
        text-transform: uppercase;
      }
    }
    .br-request-pickup-modal__form__date-picker {
      width: 284px;
    }
  }

  .br-request-pickup-modal__form__repeat-pickups__days {
    display: flex;
    .ant-btn {
      padding: 8px;
      margin-right: 12px;
      text-transform: uppercase;
      color: @text-gray;
      border: 1px solid @gray-200;
      .font({.subheading()});
    }
    .ant-btn:hover {
      color: @text-gray-dark;
    }
    .br-request-pickup-modal__form__repeat-pickups__days-selected {
      background-color: @bg-selected;
      border-color: @deep-teal;
      span {
        color: @text-gray-dark;
      }
    }
    .br-request-pickup-modal__form__repeat-pickups__days-one-selected {
      cursor: not-allowed;
    }
  }
}

.br-request-pickup-modal__form__package-type-subtitle {
  color: @text-gray;
}

.br-request-pickup-modal__form__notes-container {
  display: flex;
  flex-direction: column;
  opacity: 0;
  height: 0;
  margin: 0;
  transition: height 350ms ease 0s, opacity 350ms ease 0s, margin 350ms ease 0s;
}

.br-request-pickup-modal__form__notes-container__visible {
  height: 125px;
  opacity: 1;
  margin: -6px 0 5px 0;
}

.br-request-pickup-modal__form__date-picker {
  height: 36px;
  box-shadow: @shadow-xs;
  .ant-picker-input {
    flex-direction: row-reverse;
    .ant-picker-suffix {
      margin: 0 8px 0 0;
    }
  }
}

.br-request-pickup-modal__form__date-picker-dropdown {
  .ant-picker-panel .ant-picker-footer {
    border: none;
    box-shadow: @border-top;
  }

  .br-request-pickup-modal__date-picker__footer {
    display: flex;
    padding: 12px 0;
    .ant-btn.br-request-pickup-modal__date-picker__footer-btn {
      border-radius: 28px;
      max-height: 20px;
      padding: 8px;
      margin-right: 8px;
    }
    .ant-btn.br-request-pickup-modal__date-picker__footer-btn:not(
        .ant-btn-primary
      ) {
      background-color: @teal-100;
      border-color: @teal-200;
      color: @text-gray-dark;
    }
  }

  .ant-picker-cell .ant-picker-cell-inner,
  .ant-picker-cell-today .ant-picker-cell-inner::before {
    border-radius: 4px;
  }

  .ant-tooltip-inner {
    width: 200px;
    color: @gray-300;
    .font({.caption()});
  }

  .br-request-pickup-modal__form__date-picker-dropdown__with-tooltip {
    pointer-events: all;
    cursor: default;
  }
}

.br-request-pickup-modal__footer button {
  margin-left: 10px;
}

.br-request-pickup-modal__title {
  color: @text-color;
  font-size: 17px;
  font-family: @font-600;
  font-weight: 600;
  margin-bottom: 20px;
}

.number-of-parcels .ant-col.ant-form-item-label,
.contact-person .ant-col.ant-form-item-label {
  height: 40px;
}

.br-form-row__number-of-parcels-tooltip,
.br-form-row__contact-person-tooltip {
  color: @gray-500;
}

.ant-form.br-request-pickup-modal__form {
  padding: 16px 20px;
  .br-form-without-label.ant-row.ant-form-item {
    min-height: unset;
  }

  .br-form-row {
    .ant-col.ant-form-item-label {
      margin-bottom: 4px;
      .font({.body-medium()});
    }
  }

  .contact-person {
    margin-bottom: 0;
    height: 60px;
  }

  .br-form-row:first-of-type {
    .ant-row.ant-form-item {
      min-height: unset;
      margin-bottom: 20px;
    }
  }

  .br-pickups__pickup-settings__form__selector.ant-select {
    .ant-select-selector {
      box-shadow: @shadow-xs;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding-left: 29px;
    }
  }

  .br-pickups__pickup-settings__form__selector.ant-select
    .ant-select-selection-search {
    left: 34px;
  }

  .br-request-pickup-modal__form__select-location-prefix.ant-select
    .ant-select-selection-search::before {
    position: absolute;
    top: 7px;
    left: -24px;
    content: url('../../../../assets/bosta-icons/Location.svg');
  }

  .br-request-pickup-modal__form__select-profile-prefix.ant-select
    .ant-select-selection-search::before {
    position: absolute;
    top: 6px;
    left: -24px;
    content: url('../../../../assets/bosta-icons/Profile.svg');
  }

  .rc-virtual-list-scrollbar {
    display: none !important;
  }

  .rc-virtual-list-holder {
    > div {
      overflow: auto !important;
    }
  }

  .br-create-edit-pickup__location {
    display: flex;
    justify-content: space-between;
  }
  //less from new order creation to be removed when it's merged
  .ant-select-item-option-content {
    margin: 0;
  }

  .br-create-edit-pickup__location-item,
  .br-create-edit-pickup__location-default {
    display: flex;
    font-weight: normal;
    color: @text-gray-dark;
  }

  .br-create-edit-pickup__location-default {
    color: @text-gray;
    align-items: center;
    text-transform: uppercase;
  }

  .br-create-edit-pickup__location-city-area {
    margin-left: 8px;
    color: @text-gray;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    .br-create-edit-pickup__location-item {
      color: @gray-200;
      margin-left: 1px;
    }
    .br-create-edit-pickup__location-city-area,
    .br-create-edit-pickup__location-default {
      display: none;
    }
  }

  .ant-select:not(.ant-select-open) .br-create-edit-pickup__location-default {
    display: none;
  }

  .ant-select-item {
    margin: 4px 8px;
    padding: 8px;
  }

  .ant-select-item.ant-select-item-option-active:not(
      .ant-select-item-option-selected-disabled
    ) {
    background-color: transparent;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-selected.ant-select-item-option-active:not(
      .ant-select-item-option-disabled
    ) {
    background-color: @bg-selected;
    border-radius: 4px;
  }

  .ant-select-item-option-selected:not(
      .ant-select-item-option-disabled
    )::before {
    content: '';
    display: block;
    height: 36px;
    width: 4px;
    position: absolute;
    left: -8px;
    top: 0;
    background-color: @teal-500;
    border-radius: 0px 4px 4px 0px;
  }
  //end of duplicate less to be removed
}

.br-request-pickups-modal__select-dropdown-header {
  margin: 8px;
  padding-left: 8px;
  border-radius: 4px;
  button {
    width: 100%;
    &.ant-btn:not(.ant-btn-circle) {
      justify-content: flex-start;
    }
  }
}

.br-request-pickups-modal__select-dropdown-header:hover {
  background-color: @bg-selected;
}

.br-request-pickups-modal__select-dropdown-footer {
  cursor: auto;
  padding: 10px 0 10px 20px;
  box-shadow: @border-top;

  .br-request-pickups-modal__select-dropdown-footer-title {
    color: @text-gray;
    margin-right: 4px;
  }

  .ant-btn:not(.ant-btn-circle) {
    height: 20px;
  }
}

html[dir='rtl'] .br-request-pickups-modal__select-dropdown-footer {
  .ant-btn:not(.ant-btn-circle) {
    svg {
      transform: scaleX(-1);
    }
  }
}

.ant-btn.br-button-component.button-md.br-request-pickup-modal__form__add-notes-btn {
  height: 5px;
  margin-bottom: 28px;
  max-width: fit-content;
  opacity: 1;
  transition: height 350ms ease 0s, opacity 350ms ease 0s, margin 350ms ease 0s;
}

.ant-btn.br-button-component.button-md.br-request-pickup-modal__form__add-notes-btn__hidden {
  height: 0;
  opacity: 0;
  margin-bottom: 0;
}

.br-request-pickup-modal__create-contact,
.br-request-pickup-modal__create-location {
  display: flex;
  justify-content: flex-end;
}

.br-request-pickup-modal__form__bulky {
  .ant-checkbox + span {
    .font({.body()});
    padding: 0 10px;
  }
}

.br-request-pickup-modal__info-banner {
  margin-bottom: 24px;
}

.br-request-pickup-modal__info-banner__btns {
  display: flex;
  gap: 8px;
  margin-top: 12px;

  .br-button-component.treitary-gray {
    padding: 4px 15px;

    > span:last-child {
      width: unset;
      height: unset;
    }
  }
}

.br-request-pickup-modal__form__area-missing-error {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media only screen and (max-width: @md) {
  .br-pickups__pickup-settings__form__selector.ant-select {
    .ant-select-selection-item {
      max-width: 95%;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-request-pickup-modal .ant-modal {
    width: 100%;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: @xs) {
  .br-request-pickup-modal__info-banner__btns {
    flex-direction: column;
  }
}
