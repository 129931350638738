@import 'styles/variables';

.br-create-order-modal {
  .ant-modal-body {
    position: relative;
    max-width: 372px;
    padding: 16px;
  }
}
.br-create-order-modal__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .ant-btn {
    width: 100%;
  }
}

.br-create-order-modal__icon.anticon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @yellow-100;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  margin-top: 8px;

  svg > * {
    fill: @icon-yellow-dark;
  }
}

.br-create-order-modal__dismiss-icon {
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
}

.br-create-order-modal__title {
  margin-top: 14px;
}

.br-create-order-modal__subtitle {
  color: @text-gray;
  margin-bottom: 40px;
}
