@import './variables.less';
@import './global-styles.less';

@primary-color: @teal-500;
@layout-header-background: @background-color;
@text-color: @text-gray-dark;
// * {
//   animation-duration: 0s !important;
// }

// .Toastify__progress-bar {
//   animation-duration: 5000ms !important;
// }

.ant-collapse {
  background-color: @background-color;
}

.ant-collapse-content {
  background-color: @background-color-5;
}

.ant-layout-header {
  border-bottom: 1px solid @border-color-11;
  padding-left: 25px;
}

.ant-layout-sider {
  border-right: 1px solid @border-color-11;
}

.ant-typography.ant-typography-secondary {
  color: @text-color-2;
}

.ant-typography.ant-typography-success {
  color: @text-color-29;
}
// Tabs

.ant-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: @background-color-16;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: @text-color-27;
}

// Spin
.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ant-spin-nested-loading {
  min-width: 0;
}

.ant-spin-nested-loading,
.ant-spin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

// Table

.ant-table-thead > tr > th {
  white-space: nowrap;
  color: @text-color-2;
}

.ant-table-container table > thead > tr:first-child th {
  color: @text-gray-dark;
  font-size: 14px;
  font-weight: 500;
  height: 55px;
  border-radius: 8px 8px 0 0;
  background-color: @background-color;
  letter-spacing: 0;
  line-height: 20px;
}

.ant-table-content {
  border-radius: 4px;
  border: 1px solid @border-color-18;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid @border-color-18;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: @bg-selected;
}

.ant-table .ant-table-cell {
  // height: 20px;
  color: @text-gray-dark;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.ant-table-row {
  cursor: pointer;
}

.ant-table-content table {
  overflow: hidden;
}

// .ant-table-cell.ant-table-selection-column {
//   padding-left: 23px;
// }

// Pagination

.ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-pagination > .ant-pagination-item > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid @border-color-17;
  box-shadow: 0px 2px 2px @box-shadow;
  border-radius: 4px;
}

.ant-pagination-disabled .ant-pagination-item-link {
  background: #f7fafc;
  border: 1px solid #e2e8f0;
}

.ant-pagination-item {
  vertical-align: middle;
  .font({.button()});
  border-radius: 4px;
  box-shadow: @shadow-xs;
  border: 1px solid @gray-300;
  height: 36px;
  min-width: 38px;
  a {
    color: @text-gray-dark;
  }
}

.ant-pagination-item-active {
  border-color: @teal-500;
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item:focus a {
  color: @teal-500;
}

.ant-message {
  z-index: 2021;
}

// Modal

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 1020;
}

.ant-modal-mask {
  background: @bg-overlay-dark;
}

.ant-modal {
  box-shadow: @shadow-xl;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  padding: 16px 20px;
  height: auto;
  display: flex;
  align-items: center;
  box-shadow: @border-bottom;
  border-bottom: 0;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0;
}

.ant-modal-title {
  color: @text-gray-dark;
  .font({.display-xs()});
}

.ant-modal-close {
  display: none;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  background-color: @gray-50;
  border: 1px solid @gray-200;
  padding: 16px 20px;
  border-radius: 0px 0px 8px 8px;
}

.ant-modal-body {
  padding: 16px 20px;
}

.antd-button-common {
  border-radius: 4px;
  margin-left: 12px;
  box-shadow: @shadow-xs;
  width: fit-content;
  height: fit-content;
  padding: 8px 16px;
  .button;
}

.ant-modal-footer .ant-btn + .ant-btn-danger:not(.ant-dropdown-trigger) {
  .antd-button-common;
  color: @white;
  background: @red-500;
  border: 1px solid @red-500;
}

.ant-modal-footer .ant-btn + .ant-btn-primary:not(.ant-dropdown-trigger) {
  .antd-button-common;
  color: @white;
  background: @teal-500;
  border: 1px solid @teal-500;
}

@media only screen and (max-width: @xs) {
  .ant-modal-wrap {
    display: flex;
    align-items: center;
  }

  .ant-modal {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .ant-modal-content {
    margin: 0px;
  }
  .ant-modal-footer {
    border-radius: 0px;
  }
}

// Button

.ant-btn:not(.ant-btn-circle) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  line-height: 14px;
  // box-shadow: none;
  text-shadow: none;
}

.ant-btn svg {
  margin-right: 4px;
}

.ant-btn-primary .anticon {
  color: @btn-primary-icon-color;
}

.ant-btn.ant-btn-circle {
  box-shadow: none;
}

.ant-btn-primary:hover {
  background-color: @primary-green-hover;
  border-color: @primary-green-hover;
  color: white;
}

.ant-btn-primary span {
  color: @white;
}

.ant-btn-dangerous {
  background: @background-color-15;
  color: @text-color-15;
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  background-color: #eec201;
  border-color: #eec201;
  color: @text-color-15;
}

.ant-btn-warning {
  background: #eec201;
  color: @text-color-15;
  border-color: #eec201;
}

.ant-btn-link {
  text-decoration: underline;
}

//New Buttons
.ant-btn-brand-button {
  background: @bosta-red;
  border-color: @bosta-red;
  color: @white;
  padding: 8px 12px;
}

.ant-btn-brand-button:hover,
.ant-btn-brand-button:focus,
.ant-btn-brand-button:active {
  background-color: @red-700;
  border-color: @red-700;
  color: white;
}

.ant-btn-transparent {
  background: transparent;
  & span {
    color: @white;
  }
}

.ant-btn-transparent:hover,
.ant-btn-transparent:focus,
.ant-btn-transparent:active {
  background-color: transparent;
  border-color: @white;
  color: white;
}

.br-header-back-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  color: @back-button-color;
  font-family: @font-600;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.br-header-back-btn .anticon {
  font-size: 16px;
  margin-right: 15px;
}

.ant-dropdown {
  z-index: 999999;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active:hover {
  background-color: @gray-50;
}

.ant-dropdown-menu-item {
  padding: 9px 8px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: @white;
}

.ant-dropdown-menu-item-disabled .ant-dropdown-menu-title-content span {
  color: @gray-400;
}

.ant-dropdown-menu {
  padding: 8px;
  border-radius: 4px;
  box-shadow: @shadow-md;
  li {
    border-radius: 4px;
  }
}

// remove button animation on click
// https://segmentfault.com/q/1010000016792755/a-1020000016851747
// alternative way: https://github.com/ant-design/ant-design/issues/951#issuecomment-413730024
button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

// Form
.ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  min-height: 80px;
  margin-bottom: 0;
}

.ant-input-group.ant-input-group-compact {
  display: flex;
}

.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid @teal-400;
  box-shadow: 0 0 0 4px @teal-200;
}

.ant-form-item-has-error .ant-form-item-control-input-content {
  border-radius: 4px;
  box-shadow: 0 0 0 4px @red-100;
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ).ant-select-focused
  .ant-select-selector,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 4px @red-100;
}

.ant-form-item-has-error.ant-form-item-has-feedback .anticon-close-circle svg {
  background: transparent url('../assets/bosta-icons/alert-circle-red.svg')
    no-repeat 50% 50%;
  height: 16px;
  width: 16px;
}

.ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/Select-Arrow.svg')
    no-repeat 50% 50%;
  color: @gray-500;
  height: 16px;
  width: 12px;
  margin-top: -7px;
  & svg {
    visibility: hidden;
  }
}

.ant-select.ant-select-disabled .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/DisabledSelectArrow.svg')
    no-repeat 50% 50%;
}

.ant-pagination-options-size-changer .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/Dropdown.svg') no-repeat
    50% 50%;
  color: @gray-500;
  height: 14px;
  & svg {
    visibility: hidden;
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.ant-form-item-label,
label.ant-form-item-required,
.br-form-item-label {
  width: 100%;
}

// .ant-row.ant-form-item:not(:last-child) {
//   margin-right: 22px;
// }

.ant-form-item-control,
.ant-form-item-control .ant-picker {
  width: 100%;
  .ant-picker-panel-container {
    border-radius: 4px;
    .ant-picker-panel {
      border: none;
      box-shadow: @shadow-xs;
    }
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: @bg-hovered;
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view):not(.ant-picker-cell-selected),
  .ant-picker-cell-disabled {
    color: @text-gray-light;
  }

  .ant-picker-cell-disabled::before {
    background: transparent;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-radius: 4px;
  }
}

.ant-form-item-control .ant-picker.ant-picker-focused {
  border-color: @teal-400;
  box-shadow: @shadow-xs-green;
}

.ant-form-item-control .ant-picker:hover {
  border-color: @teal-500;
}

.ant-form-item > .ant-form-item-label {
  height: 20px;
  margin-bottom: 4px;
  text-align: start;
}

.ant-form-item > .ant-form-item-label > label,
.ant-form-item > .ant-form-item-label > label > span {
  height: 20px;
  color: @text-gray-dark;
  margin-bottom: 0;
  .font({.body-medium()});
}

.br-forms-new-input .ant-form-item > .ant-form-item-label > label,
.br-forms-new-input .ant-form-item > .ant-form-item-label > label > span {
  width: 100%;
}

.ant-form-item-label > label {
  // margin-right: 8px;
  font-family: @font-500;
}

// .ant-form:not(.ant-form-hide-required-mark) .ant-form-item-label .ant-form-item-required::after {
//   display: inline-block;
//   margin-left: 8px;
//   color: #ff4d4f;
//   font-size: 14px;
//   font-family: @font-500;
//   line-height: 1;
//   content: '*';
// }

// .ant-form .ant-form-item-label > label:not(.ant-form-item-required)::after {
//   display: inline-block;
//   margin-left: 8px;
//   color: #909cad;
//   font-size: 13px;
//   vertical-align: middle;
//   content: '-optional-';
// }

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

//for : in form group
.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-has-error.ant-form-item-has-feedback .anticon-close-circle,
.ant-form-item-has-success.ant-form-item-has-feedback .anticon-check-circle {
  svg {
    color: transparent;
  }
}

.ant-form-item-explain,
 .ant-form-item-extra  {
  margin-top: 2px;
  min-height: unset;
}

.ant-form-item-explain-error {
  color: @red-500;
  .font({.body()});
}

html[dir='ltr'] .ant-form-item-explain-error::first-letter {
  text-transform: capitalize;
}

// fixme: Check what its used for
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: unset;
}

.ant-input-affix-wrapper,
.ant-select-selector,
.ant-form-item-control-input-content,
.ant-form-item-control-input {
  min-height: 36px;
}

.ant-input-affix-wrapper,
.ant-form-item-control-input
  .ant-input-group:not(.ant-input-group-compact)
  .ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid @gray-300;
  .font({.body()});
  .ant-input-prefix {
    color: @text-gray-dark;
    .font({.body()});
  }
}

.ant-input {
  .font({.body()});
  color: @text-gray-dark;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
  .ant-input-prefix {
    color: @text-gray;
  }
}

@media only screen and (max-width: 991px) {
  .ant-row.ant-form-item {
    // min-height: unset;
    // margin-bottom: 10px;
  }

  .ant-picker-panels {
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .ant-row.ant-form-item,
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: unset;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
}

// Input Password Select DropdownButton

.ant-input,
.ant-input-password,
.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker,
.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary) {
  border: 1px solid @gray-300;
  border-radius: 4px;
}

.ant-select-dropdown {
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: @shadow-md;
}

.ant-btn.ant-dropdown-trigger .anticon.anticon-down {
  color: @ant-icon-down-color;
}

button.ant-btn.ant-btn-brand-button.ant-dropdown-trigger:not([disabled]) {
  border: 1px solid @bosta-red;
}

button.ant-btn.ant-btn-brand-button.ant-dropdown-trigger:not([disabled]):hover,
button.ant-btn.ant-btn-brand-button.ant-dropdown-trigger:not([disabled]):focus {
  border-color: @red-700;
}

.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary):hover,
.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-color: @primary-color;
}

.ant-input-affix-wrapper-focused {
  border: 1px solid @teal-300;
  box-shadow: @shadow-xs-green;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-radius: 4px;
  &:focus {
    border: 1px solid @teal-400;
    box-shadow: @shadow-xs-green;
  }
}

//for cursor not be in top but in the same line with placeholder
.ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-select-selector input {
  margin-top: 8px;
}

.ant-select-selection-placeholder {
  opacity: 0.5 !important;
}

.ant-input[disabled].ant-input-disabled,
.ant-input-search.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: @bg-gray-light;
  border-color: @gray-200;
  color: @text-gray-light;
}

.ant-select-disabled .ant-select-arrow {
  svg path {
    fill: @icon-disabled;
  }
}

.ant-input::placeholder,
.ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  color: @text-gray-light;
  opacity: 1 !important;
  .font({.body()});
}

.ant-select-item-option-content {
  white-space: break-spaces;
  margin-bottom: 2px;
}

.ant-select-item {
  color: @text-gray-dark;
  min-height: 36px;
  align-items: center;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled):not(
    .ant-select-item-option-selected
  ) {
  background-color: @bg-hovered;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: @teal-100;
  color: @text-gray-dark;
}

// Checkbox
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: unset;
}

// Radio
.ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner {
  border-color: @border-color-16;
}

.ant-radio .ant-radio-checked .ant-radio-inner {
  border-color: @border-color-16;
}

.ant-radio .ant-radio-checked::after {
  border: 1px solid @border-color-16;
}

.ant-radio.ant-radio-checked .ant-radio-inner {
  border: 5px solid @deep-teal;
}

.ant-radio .ant-radio-inner::after {
  background-color: @white;
  transform: scale(0.4);
}

.ant-radio.ant-radio-disabled:hover,
.ant-radio.ant-radio-disabled {
  .ant-radio-inner {
    border: 1px solid @gray-200;
  }
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: @background-color-49;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:hover {
  background-color: @background-color-49;
}

.ant-radio-button-wrapper:hover {
  box-shadow: @shadow-xs-green;
  z-index: 1;
}

.ant-radio-button-disabled {
  background-color: @bg-disabled;
  border: 1px solid @gray-200;
  span {
    color: @text-gray-light;
  }
}

// Checkbox

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled)
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: @teal-500;
  border-color: @teal-500;
  border-radius: 4px;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
  color: @text-gray-light;
}

.ant-checkbox-wrapper span {
  color: @text-gray-dark;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 4px;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: @teal-500;
  border: 1px solid @teal-500;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: @white;
  border-color: @white;
  height: 1px;
  width: 8px;
}

// Date picker - fix global style override that mixed with its height

table.ant-picker-content tbody {
  height: auto;
}

// Tag

.ant-tag {
  .font({.caption-lg()});
  border-radius: 4px;
}

.ant-tag-yellow {
  color: @yellow-900;
  background: @yellow-100;
  border-color: @yellow-100;
}

.ant-tag-orange {
  color: @tag-orange-text-color;
  background: @tag-orange-background-color;
  border-color: @tag-orange-background-color;
}

.ant-tag-brown {
  color: @text-color-49;
  background: @tag-light-brown-background-color;
  border-color: @tag-light-brown-background-color;
}

.ant-tag-light-blue {
  color: @blue-900;
  background: @blue-100;
  border-color: @blue-100;
}

.ant-tag-green {
  color: @green-900;
  background: @green-100;
  border-color: @green-100;
}

.ant-tag-red {
  color: @tag-red-text-color;
  background: @tag-red-background-color;
  border-color: @tag-red-background-color;
}

.ant-tag-light-gray {
  color: @text-gray;
  background: @gray-100;
  border-color: @gray-100;
}

.ant-tag-dark-gray {
  color: @text-gray;
  background: @gray-200;
  border-color: @gray-200;
}

.ant-tag-purple {
  color: @white;
  background: @purple-500;
  border-color: @purple-500;
}

// popconfirm
.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

// tooltip

.ant-tooltip {
  width: fit-content;
}

.ant-tooltip-inner {
  background: @gray-800;
  color: @gray-300;
  text-align: start;
  border-radius: 4px;
  padding: 8px;
  white-space: normal;
  width: fit-content;
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background: @gray-800;
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content::before {
  display: none;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: @gray-800;
}

// popover

.ant-popover {
  width: fit-content;
}

.ant-popover-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: @background-color-52;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}

.ant-popover-inner {
  border-radius: 8px;
}

.ant-popover-inner-content {
  padding: 0;
}

// icon

.anticon-info-circle {
  color: @text-color-8;
  font-size: 12px;
  margin-left: 5px;
}

// arabic

/* rtl:begin:ignore */

[dir='rtl'] .ant-dropdown,
.ant-select-dropdown {
  right: unset !important;
}

.ant-dropdown-menu-title-content span {
  color: @gray-900;
}

html[dir='rtl'] {
  .br-header-card__footer > div {
    left: unset !important;
  }

  #br-popover-container .ant-popover {
    left: 0 !important;
    right: unset !important;
  }

  .ant-popover-arrow {
    right: 18px !important;
  }
}

html[dir='rtl'] {
  // .ant-picker {
  //   padding: 4px 11px 0px !important;
  // }
  .ant-picker-focused .ant-picker-input-active {
    border-bottom: 2px solid #00b887;
  }
  .ant-picker .ant-picker-active-bar,
  .ant-picker-range-arrow {
    display: none !important;
  }
  .ant-picker-input {
    height: 100%;
  }
}

html[dir='rtl'] {
  .anticon-left,
  .br-header__go-back-btn {
    transform: rotate(180deg);
  }
}

html[dir='rtl'] .anticon-right {
  transform: rotate(-180deg);
}

html[dir='rtl'] .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  transform: scaleX(-1);
}

//sekeleton moving problem
.bg-skeleton-fix {
  background-size: 400% 100% !important;
}

.ant-skeleton {
  &.@{skeleton-prefix-cls}-active {
    & .@{skeleton-prefix-cls}-content {
      .@{skeleton-title-prefix-cls},
      .@{skeleton-paragraph-prefix-cls} > li {
        .bg-skeleton-fix();
      }
    }
    .@{skeleton-avatar-prefix-cls} {
      .bg-skeleton-fix();
    }
    .@{skeleton-button-prefix-cls} {
      .bg-skeleton-fix();
    }
    .@{skeleton-input-prefix-cls} {
      .bg-skeleton-fix();
    }
    .@{skeleton-image-prefix-cls} {
      .bg-skeleton-fix();
    }
  }
}

//ant-picker

.ant-picker-cell-range-start .ant-picker-cell-inner {
  border-radius: 17px 0px 0px 17px !important;
}

.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 0px 17px 17px 0px !important;
}

html[dir='rtl'] .ant-picker-cell-range-start .ant-picker-cell-inner {
  border-radius: 0 17px 17px 0 !important;
}

html[dir='rtl'] .ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 17px 0 0 17px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background-color: @white !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: @white !important;
}

.ant-picker-cell-in-range .ant-picker-cell-inner {
  background-color: @teal-200 !important;
  padding: 0 8px;
  width: 32px;
}

.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after {
  background-color: @teal-300 !important;
}

.ant-picker-time-panel .ant-picker-content {
  padding-top: 8px !important ;
}

.ant-picker-time-panel-column {
  padding: 8px !important;
}

.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background-color: @teal-200 !important;
    border-radius: 4px !important;
  }
}

.ant-picker-time-panel-cell-inner {
  display: flex !important;
  justify-content: center;
  padding: 0px !important;
}

[dir='rtl'] .ant-picker-dropdown {
  right: unset;
}

[dir='rtl'] #bubble-preview-wrapper {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}
