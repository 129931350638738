@import 'styles/variables';

.br-activation-modal {
  .ant-modal {
    max-width: 372px;
    max-height: 284px;
  }
  .ant-modal-body {
    padding: 0px;
  }
}

.br-activation-modal {
  .br-activation-modal__header,
  .br-activation-modal__header .br-activation-modal__content,
  .br-activation-modal__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.br-activation-modal__content {
  justify-content: end;
}

.br-activation-modal .br-activation-modal__header {
  height: 168px;
  padding: 20px;
  justify-content: space-between;
  span[role='img'] {
    height: 56px;
    display: flex;
    align-items: center;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .br-activation-modal__content {
    height: 68px;
    .br-activation-modal__title {
      color: @text-gray-dark;
    }
    .br-activation-modal__subtitle {
      color: @text-gray;
      text-align: center;
    }
  }
}

.br-activation-modal .br-activation-modal__header-small {
  height: 132px;
}

.br-activation-modal .br-activation-modal__footer {
  width: 100%;
  height: 116px;
  justify-content: space-between;
  padding: 16px 20px;
  .ant-btn {
    width: 100%;
  }
}

.br-activation-modal .br-activation-modal__footer-small {
  height: 68px;
}

.br-draft-orders__sllr-activation-modal {
  display: flex;
  align-items: center;
  gap: 8px;
  .br-draft-orders__activation-modal-img {
    max-width: 90px;
  }
}
