@import 'styles/variables';

.br-scheduling-pickup-modal {
  text-align: center;

  .ant-modal {
    border-radius: 8px;
    max-width: 372px;
    max-height: 284px;
    padding: 0px;
  }

  .br-scheduling-pickup-modal__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading;
    .clickable-icon {
      cursor: pointer;
    }

    .visibility-hidden {
      visibility: hidden;
    }
  }
}

.ant-progress.br-scheduling-pickup-modal__progress {
  height: auto;
  display: flex;
  justify-content: center;
  margin: 24px auto;

  .ant-progress-inner {
    max-width: 80px;
    max-height: 80px;

    .ant-progress-circle-path {
      stroke: @teal-500;
    }
  }
}

.br-scheduling-pickup-modal__introduction-step {
  .br-scheduling-pickup-modal__header {
    align-items: flex-start;
  }

  .br-scheduling-pickup-modal__step-progress {
    background: @gray-100;
  }

  .br-scheduling-pickup-modal__title {
    margin: 16px 0 0;
    .display-sm;
  }

  .br-scheduling-pickup-modal__subtitle {
    padding-bottom: 8px;
  }

  .br-scheduling-pickup-modal__introduction-step__steps-content {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    padding-bottom: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid @gray-200;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: none;
    }
  }

  .br-scheduling-pickup-modal__header > svg {
    position: absolute;
    right: 0;
  }

  .br-scheduling-pickup-modal__footer {
    margin-top: 24px;
  }
}

.br-scheduling-pickup-modal__content {
  text-align: center;
}

.br-scheduling-pickup-modal__step-progress {
  width: 71px;
  height: 71px;
  margin: auto;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.br-scheduling-pickup-modal__step-tracker {
  text-transform: uppercase;
}

.br-scheduling-pickup-modal__title {
  margin-bottom: 8px;
  .display-xs;
}

.br-scheduling-pickup-modal__step-tracker,
.br-scheduling-pickup-modal__subtitle {
  margin: auto;
  color: @text-gray;
}

.br-scheduling-pickup-modal__footer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 36px;

  .br-button-component {
    svg {
      width: 20px;
      height: 20px;
    }

    .icon-white-color svg path {
      fill: none;
      stroke: @white;
    }

    &.br-button-component__with-suffix {
      gap: 4px;
    }
  }
}
