.br-international-service__container {
  max-width: 536px;

  .ant-space-item-disabled {
    min-height: auto;
  }

  .ant-checkbox {
    display: none;
  }

  .br-signup__services-container {
    padding: 32px 40px;
  }

  .br-services-checkbox-group {
    margin-top: 0;
  }
}
